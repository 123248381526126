import React, { useState, useEffect, useRef } from "react";
import { FiCheck } from "react-icons/fi";
import { FiLock } from "react-icons/fi";
import { FaRegEnvelope, FaCity } from "react-icons/fa";
import { AiOutlineUser, AiOutlinePhone } from "react-icons/ai";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Select, Button, notification } from "antd";

import { getCities } from "api/collection";
import { updateProfile } from "api/auth";
import { clearStorage } from "lib/utils";
import { useDispatch } from "react-redux";

const { Option } = Select;
const acceptedFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif";

function UserSidebar({ user, fullName, setFullName }) {
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [avatar, setAvatar] = useState(user?.thumbnail);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);

  const initial = {
    firstname: user.first_name,
    lastname: user.last_name,
    email: user.email,
    mobile: user.mobile,
    city: parseInt(user?.city),
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const profileUpdateSchema = Yup.object().shape(
    {
      firstname: Yup.string().required("Required"),
      lastname: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      mobile: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      oldPassword: Yup.string().when("newPassword", {
        is: (newPassword) => newPassword?.length > 0,
        then: Yup.string().required("Password is required"),
      }),
      newPassword: Yup.string()
        .when("oldPassword", {
          is: (oldPassword) => {
            return oldPassword?.length > 0;
          },
          then: Yup.string().notOneOf(
            [Yup.ref("oldPassword"), null],
            "Old and New password shouldn't be same"
          ),
        })
        .when("oldPassword", {
          is: (oldPassword) => oldPassword?.length > 0,
          then: Yup.string()
            .required("Password is required")
            .min(6, "Password must be at least 6 characters"),
        }),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords don't match!")
        .when("newPassword", {
          is: (newPassword) => newPassword?.length > 0,
          then: Yup.string()
            .required("Password is required")
            .min(6, "Password must be at least 6 characters"),
        }),
    },
    [["oldPassword", "newPassword", "confirmPassword"]]
  );
  const onSubmit = async (values, { setFieldError }) => {
    try {
      setLoading(true);
      if (avatar !== user.thumbnail) {
        values.profileImage = file;
      }
      let formData = new FormData();
      for (const key in values) {
        if (values[key] !== initial[key] || key === "profileImage") {
          // console.log(key, values[key], initial[key]);
          formData.append(key, values[key]);
          // console.log(formData);
        }
      }
      formData.delete("confirmPassword");
      const res = await updateProfile(user.id, formData);
      const updated_user = JSON.stringify(res.data.response);
      localStorage.setItem("openbarcity_user", updated_user);
      setFullName(
        JSON.parse(updated_user).first_name +
          " " +
          JSON.parse(updated_user).last_name
      );
      notification.success({
        message: "Profile updated successfully",
        placement: "bottomLeft",
        duration: 8,
      });
    } catch (error) {
      if (error.response.status === 400) {
        let errors = error.response.data;
        if (
          errors["non_field_errors"][0] === "The old password is incorrect."
        ) {
          notification.error({
            message: "The old password is incorrect.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
        for (let [key, value] of Object.entries(errors)) {

          if (key === "avatar") {
            setFileError(value);
          } else {
            setFieldError(key, value);
          }
        }
      }
      if (error.response.status === 401) {
        clearStorage(dispatch);
      }
    } finally {
      setLoading(false);
    }
  };
  const fileClick = () => {
    fileRef.current.click();
  };
  const fileChange = async (evt) => {
    if (evt.target.files && evt.target.files.length > 0) {
      setFileError(null);
      setFile(evt.target.files[0]);
      try {
        const url = await getImageUrl(evt.target.files[0]);
        setAvatar(url);
      } catch (error) {
        setFileError(error);
      }
    }
  };
  let getImageUrl = (files) => {
    var file = Array.isArray(files) ? files[0] : files;
    var reader = new FileReader();
    let src = "";
    return new Promise((resolve, reject) => {
      if (file.size < 5000000) {
        reader.onloadend = function () {
          src = reader.result;
          if (src.indexOf("data:image") > -1 || src.indexOf("data:video") > -1)
            resolve(src);
          else if (src.indexOf("data:image") < 0)
            reject("Please upload an image");
        };
        if (file) {
          reader.readAsDataURL(file);
        } else {
          src = "";
          reject("No Files");
        }
      } else {
        reject("Upload an image with size less than 5MB");
      }
    });
  };

  useEffect(() => {
    let isCancelled = false;
    async function getData() {
      try {
        const citiesList = await getCities();
        if (citiesList.data) {
          if (!isCancelled) {
            setCities(citiesList.data.response);
          }
        }
      } catch (error) {
        clearStorage(dispatch);
      }
    }
    getData();
    return () => {
      isCancelled = true;
    };
  }, [dispatch]);

  return (
    <div className="contact-form-action">
      <Formik
        initialValues={initial}
        validationSchema={profileUpdateSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          /** and other goodies */
        }) => (
          <Form>
            <div className="author-bio margin-bottom-30px">
              <div className="d-flex align-items-center">
                <img src={avatar} alt="author" onClick={fileClick} />
                <input
                  type="file"
                  accept={acceptedFileTypes}
                  ref={fileRef}
                  onChange={fileChange}
                  onClick={fileChange}
                  style={{ display: "none" }}
                />

                <div className="author-inner-bio">
                  <h4 className="author__title font-weight-bold pb-0 mb-1">
                    {fullName}{" "}
                    <i
                      className="la tip tip-verified"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Verified Account"
                    >
                      <FiCheck />
                    </i>
                  </h4>
                </div>
              </div>
              <span className="ant-form-item-explain">{fileError}</span>
            </div>
            <div className="section-block-2"></div>
            <div className="user-contact padding-top-30px">
              <h3 className="widget-title pb-0 margin-bottom-20px">
                Contact Details
              </h3>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="form-group">
                      <span className="form-icon">
                        <AiOutlineUser />
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="firstname"
                        placeholder="Enter first name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstname}
                      />
                      {errors.firstname && touched.firstname ? (
                        <div className="ant-form-item-explain">
                          {errors.firstname}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="form-group">
                      <span className="form-icon">
                        <AiOutlineUser />
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="lastname"
                        placeholder="Enter last name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastname}
                      />
                      {errors.lastname && touched.lastname ? (
                        <div className="ant-form-item-explain">
                          {errors.lastname}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="form-group">
                      <span className="form-icon">
                        <FaRegEnvelope />
                      </span>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        // disabled
                      />
                      {errors.email && touched.email ? (
                        <div className="ant-form-item-explain">
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="form-group">
                      <span className="form-icon">
                        <AiOutlinePhone />
                      </span>
                      <input
                        className="form-control"
                        type="tel"
                        name="mobile"
                        placeholder="Enter phone number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobile}
                        // disabled
                      />
                      {errors.mobile && touched.mobile ? (
                        <div className="ant-form-item-explain">
                          {errors.mobile}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="form-group">
                      <span className="form-icon">
                        <FaCity />
                      </span>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select a city"
                        onChange={(value) => setFieldValue("city", value)}
                        onBlur={handleBlur}
                        value={values.city}
                      >
                        {cities &&
                          cities.length > 0 &&
                          cities.map((city) => {
                            return (
                              <Option key={city.id} value={city.id}>
                                {city.name}
                              </Option>
                            );
                          })}
                      </Select>
                      {errors.city && touched.city ? (
                        <div className="ant-form-item-explain">
                          {errors.city}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="form-group">
                      <span className="form-icon">
                        <FiLock />
                      </span>
                      <input
                        className="form-control"
                        type="password"
                        name="oldPassword"
                        placeholder="Enter old password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.oldPassword}
                      />
                      {errors.oldPassword && touched.oldPassword ? (
                        <div className="ant-form-item-explain">
                          {errors.oldPassword}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="form-group">
                      <span className="form-icon">
                        <FiLock />
                      </span>
                      <input
                        className="form-control"
                        type="password"
                        name="newPassword"
                        placeholder="Enter new password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                      />
                      {errors.newPassword && touched.newPassword ? (
                        <div className="ant-form-item-explain">
                          {errors.newPassword}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="form-group">
                      <span className="form-icon">
                        <FiLock />
                      </span>
                      <input
                        className="form-control"
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm new password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <div className="ant-form-item-explain">
                          {errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="btn-box margin-top-20px margin-bottom-20px">
                    <Button
                      type="primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                      loading={loading}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
              <div className="section-block-2"></div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UserSidebar;

/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Thursday 01, Feb 2024
 * File: Affiliate.js
 * Dir: frontend/src/pages
 * Project: openbarcity
 */

import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {notification} from "antd";
import {influenceSignin} from "../../api/auth";
import {setInfluenceHeaders} from "../../lib/utils";

const Affiliate = ({formData, handleInputChange}) => {
    const {username, password} = formData;
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const history = useHistory();

    const validateForm = () => {
        let isValid = true;
        // Email validation
        if (username.trim() === '') {
            setEmailError('Username is required.');
            isValid = false;
        } else {
            setEmailError('');
        }
        // Password validation
        if (password.trim() === '') {
            setPasswordError('Password is required.');
            isValid = false;
        } else {
            setPasswordError('');
        }
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            // Perform form submission logic for valid data
            try {
                const res = await influenceSignin(formData);
                setInfluenceHeaders(res, history, '/influence');
                history.push('/influence');
            } catch (error) {
                let description = "";
                if (error.response.status === 400)
                    description = error.response.data;
                notification.error({
                    message: "Influence Signin failed.",
                    description,
                    placement: "bottomLeft",
                    duration: 8,
                    bottom: 100
                });
            } finally {

            }
        } else {
            // Handle validation error
            notification.error({
                message: "Signin failed.",
                description: "Validation error: Username and password are required.",
                placement: "bottomLeft",
                duration: 8,
                bottom: 100
            });
        }
    };

    return (
        <div className="tab-pane fade show active" id="nav-affiliate" role="tabpanel" aria-labelledby="nav-profile-tab">
            <label htmlFor="inputUsername">Username</label>
            <input
                type="text"
                id="inputUsername"
                className={`form-control ${emailError ? 'is-invalid' : ''}`}
                placeholder="Enter Username"
                required
                autoFocus
                name="username"
                value={username}
                onChange={handleInputChange}
            />
            {emailError && <div className="invalid-feedback">{emailError}</div>}
            <label htmlFor="inputPassword" style={{marginTop: '1rem'}}>Password</label>
            <input
                type="password"
                id="inputPassword"
                className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                placeholder="Enter Password"
                required
                name="password"
                value={password}
                onChange={handleInputChange}
            />
            {passwordError && <div className="invalid-feedback">{passwordError}</div>}
            <p className="mt-1 mb-3 text-right">
                <button type="button" className="link-black d-none">
                    Forgot Password
                </button>
            </p>
            <button className="btn btn-lg btn-primary btn-block" type="submit" onClick={handleSubmit}>
                Sign in
            </button>
        </div>
    );
};

export default Affiliate;
import React, {useState} from 'react';
import {Formik, Form} from 'formik';
import {AiOutlineUser} from 'react-icons/ai'
import {FiLock} from 'react-icons/fi'
import {useHistory} from "react-router-dom";
import * as Yup from 'yup';
import {Button, notification} from 'antd';
import {barSignin} from 'api/auth';
import {setBarHeaders} from 'lib/utils';

function BarLogin() {
    const history = useHistory();

    const [barLoading, setBarLoading] = useState(false);

    const loginInitial = {
        'email': '',
        'password': '',
    }
    const loginValidationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Required'),
    })
    const onBarSubmit = async (values, {setFieldError}) => {
        try {
            setBarLoading(true);
            // login stuff
            let formData = new FormData();
            for (const key in values) {
                formData.append(key, values[key]);
            }
            const res = await barSignin(formData);
            setBarHeaders(res, history, '/bar');
        } catch (error) {
            let description = "";
            if (error.response.status === 400)
                description = Object.values(error.response.data).join();
            notification.error({
                message: "Bar Signin failed.",
                description,
                placement: "bottomLeft",
                duration: 8,
                bottom: 100
            });
        } finally {
            setBarLoading(false);
        }
    }
    return (
        <div className="billing-form-item mb-0">
            <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
                <h3 className="widget-title font-size-28 pb-0">
                    Bar Login
                </h3>
            </div>
            <div className="billing-content">
                <div className="contact-form-action">
                    <Formik
                        initialValues={loginInitial}
                        validationSchema={loginValidationSchema}
                        onSubmit={onBarSubmit}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                          }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <label className="label-text">Email</label>
                                            <div className="form-group">
                        <span className="form-icon">
                          <AiOutlineUser/>
                        </span>
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                />
                                                {errors.email && touched.email ? (
                                                    <div className="ant-form-item-explain">
                                                        {errors.email}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <label className="label-text">Password</label>
                                            <div className="form-group">
                        <span className="form-icon">
                          <FiLock/>
                        </span>
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                />
                                                {errors.password && touched.password ? (
                                                    <div className="ant-form-item-explain">
                                                        {errors.password}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="btn-box margin-top-20px margin-bottom-20px">
                                            <Button
                                                type="primary"
                                                className="width100"
                                                onClick={() => {
                                                    handleSubmit();
                                                }}
                                                loading={barLoading}
                                            >
                                                Login now
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default BarLogin;
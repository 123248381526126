import React from 'react';
import { Spin, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const deviceWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

export default function Loader() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="loader-container">
      <Spin indicator={antIcon} />
    </div>
  );
}

export const CustomLoader = () => {
  return (
    <div className="loader-container">
      <Spin tip="Loading..." />
    </div>
  );
};

export const SkeletonLoader = props => {
  const buttonCount = deviceWidth > 1600 ? 50 : 32;
  return (
    <div className={`c-story-loader ${props.type}`}>
      {Array(buttonCount)
        .fill()
        .map((_, i) => (
          <Skeleton.Button active={true} size="large" shape="square" key={i} />
        ))}
      <button className="c-story-loader--label">Loading stories...</button>
    </div>
  );
};

// <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
export function Spinner({ color, ...rest }) {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={color} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );
}

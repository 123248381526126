import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute({component: Component, ...rest}) {
    let isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const isInfluenceLoggedIn = useSelector(state => state.auth.isInfluenceLoggedIn);
    const isBarLoggedIn = useSelector(state => state.auth.isBarLoggedIn);
    const isAuthenticated = () => {
        if (isLoggedIn || isInfluenceLoggedIn || isBarLoggedIn) {
            return true;
        } else {
            const { openbarcity_user_id, openbarcity_user, openbarcity_token } = localStorage;
            if (openbarcity_user_id && openbarcity_user && openbarcity_token) {
                return true;
            }
            return false;
        }
    };

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{pathname: '/', state: { referer: props.location }}} />
                )}
        ></Route>
    )
};

export default PrivateRoute;
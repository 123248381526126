import React, { useState } from 'react';
import { FiLock } from 'react-icons/fi'
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {notification, Button} from 'antd';
import { updatePassword } from 'api/auth';
import { useParams } from 'react-router-dom';

function RecoverPassBox() {
    const {token} = useParams();
    const [loading, setLoading] = useState(false);
    const initial = {
        'password': '',
        'confirm_password': ''
    }
    const schema = Yup.object().shape({
        password: Yup.string().required('Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords don't match!")
            .required('Required'),
    })
    const onSubmit = async (values) => {
        try {
            setLoading(true);
            let formData = new FormData();
            for (const key in values) {
                formData.append(key, values[key]);
            }
            formData.delete('confirm_password')
            formData.append('token', token);
            await updatePassword(formData);
            notification.success({
                message: 'You password was reset successfully!',
                placement: "bottomLeft",
                duration: 8,
                bottom: 100
            })
        } catch (error) {
            let description = "";
            if (error.response.status === 400 || error.response.status === 429)
                description = Object.values(error.response.data).join();
            notification.error({
                message: "Error",
                description,
                placement: "bottomLeft",
                duration: 8,
                bottom: 100
            });
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <section className="form-shared padding-top-120px padding-bottom-100px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="billing-form-item mb-0">
                                <div className="billing-title-wrap">
                                    <h3 className="widget-title font-size-28">Reset Password!</h3>
                                </div>
                                <div className="billing-content">
                                    <div className="contact-form-action">
                                        <Formik
                                            initialValues={initial}
                                            validationSchema={schema}
                                            onSubmit={onSubmit}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                            }) => (
                                                <Form>
                                                    <div className="input-box">
                                                    <div className="col-lg-12">
                                                        <div className="input-box">
                                                            <label className="label-text">New Password</label>
                                                            <div className="form-group">
                                                                    <span className="form-icon">
                                                                        <FiLock />
                                                                    </span>
                                                                <input 
                                                                    className="form-control"
                                                                    type="password" 
                                                                    name="password" 
                                                                    placeholder="Enter new password"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.password} 
                                                                />
                                                                {errors.password && touched.password ? (
                                                                    <div className="ant-form-item-explain">
                                                                        {errors.password}
                                                                    </div>
                                                                ): null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="input-box">
                                                            <label className="label-text">Confirm Password</label>
                                                            <div className="form-group">
                                                                <span className="form-icon">
                                                                    <FiLock />
                                                                </span>
                                                                <input 
                                                                    className="form-control"
                                                                    type="password" 
                                                                    name="confirm_password" 
                                                                    placeholder="Enter confirm password"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.confirm_password} 
                                                                />
                                                                {errors.confirm_password && touched.confirm_password ? (
                                                                    <div className="ant-form-item-explain">
                                                                        {errors.confirm_password}
                                                                    </div>
                                                                ): null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="btn-box margin-top-20px margin-bottom-20px">
                                                        <Button
                                                            type="primary"
                                                            className="width100 theme-btn border-0"
                                                            onClick={() => {
                                                                handleSubmit();
                                                            }}
                                                            loading={loading}
                                                        >
                                                            reset password
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RecoverPassBox;
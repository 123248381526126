/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Tuesday 13, Feb 2024
 * File: index.js
 * Dir: frontend/src/pages/hotels
 * Project: openbarcity
 */

import React from "react";
import Footer from "components/common/footer/Footer";
import GeneralHeader from "components/common/GeneralHeader";
import ScrollTopBtn from "components/common/ScrollTopBtn";

const Hotels = () => {
    return (
        <main className="List-map-view2 background-city">
            <GeneralHeader/>
            <section className="cta-area  column-sm-center padding-top-120px">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 bg-white p-5 rounded">
                            <article>
                                <h2 className="text-center mb-3">Meet our Hotel partners </h2>
                                <p className="mb-3">
                                    Hotels are excited to team up with these wonderful Bar and Restaurants
                                    located in our cities. Since hotels require only the best experiences for
                                    theirguests, our partner Bars and Restaurants are recognized as the top in
                                    culinary destinations of the city.
                                </p>
                                <p className="mb-3">
                                    Proud of the additional work hotels are doing to bring the Travel with Impact
                                    mission to the city, sustainable travel is not just an afterthought it is now the
                                    norm. Hotels are our largest contributors to our hunger relief mission in the
                                    city. Enabling their guests to be a part of the solution for sustainable travel,
                                    and keeping their claims of service to the community transparent and fully
                                    substantiated.
                                </p>
                                <p className="mb-3 text-success">
                                    THANK YOU HOTEL PARTNERS!
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
            <ScrollTopBtn/>
        </main>
    );
};

export default Hotels;

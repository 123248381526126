/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Tuesday 13, Feb 2024
 * File: index.js
 * Dir: frontend/src/pages/restaurantWeek
 * Project: openbarcity
 */

import React from "react";
import Footer from "components/common/footer/Footer";
import GeneralHeader from "components/common/GeneralHeader";
import ScrollTopBtn from "components/common/ScrollTopBtn";

const RestaurantWeek = () => {
    return (
        <>
            <main className="List-map-view2 background-city">
                <GeneralHeader/>
                <section className="cta-area  column-sm-center padding-top-120px">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 bg-white p-5 rounded">
                                <article>
                                    <h2 className="text-center mb-3">Meet our Restaurant Week Event partners</h2>
                                    <p className="mb-3">
                                        With the popularity and success of Restaurant Week events, the
                                        creation of our app for the use of a Restaurant Week events as its
                                        platform works together well. Restaurant Week organizations bringincreased users
                                        and
                                        the awareness to the app, essential for our key
                                        sponsors to get noticed.
                                    </p>
                                    <blockquote className="text-success">
                                        One Purchased = One Donated- For every meal purchased through the app a meal
                                        redeemable at a participating restaurant is donated to a hunger relief center.
                                    </blockquote>
                                    <p>
                                        Every time a guest participates in a city's Restaurant Week event listed
                                        through our app OpenBar City, the purchase is tracked and information is
                                        sent to our sponsors who double the purchase of those meals and sends
                                        the donations to local hunger relief centers. The recipient also uses the
                                        app to redeem their meal in the same way as a purchasing guest.
                                    </p>
                                    <p>
                                        Master Craft spirits, our key sponsor has expressed only appreciation and
                                        delight to have the opportunity to participate in this worthy cause.
                                    </p>
                                </article>
                                <article className="m-3">
                                    <h4 className="text-center p-3">Our Openbar City app gives users the ability
                                        to…</h4>
                                    <ol>
                                        <li>View available events dates and times.</li>
                                        <li>Purchase Restaurant Week Event tickets.</li>
                                        <li>Redeem meals through the app at participating Bars or Restaurants.</li>
                                        <li>Notifications when new Restaurant Week events become available.</li>
                                        <li>List Bar or Restaurant details such as dress codes and other specific
                                            information.
                                        </li>
                                    </ol>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer/>
                <ScrollTopBtn/>
            </main>
        </>
    );
};

export default RestaurantWeek;
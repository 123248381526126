import React from "react";

const Title = ({ title, subTitle }) => {
  return (
    <div className="hero-wrapper page__title-wrapper">
      <div className="section-heading home-section-heading">
        <h2 className="sec__title cd-headline zoom page__title">{title}</h2>
        <p className="sec__desc page__subtitle">{subTitle}</p>
      </div>
    </div>
  );
};

export default Title;

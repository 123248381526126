export const createReducer = (initialState, handlers, namespaces = []) => {
    return (state = initialState, action) => {
        if (namespaces.includes(getNamespace(action.type))) {
            if (handlers[action.type]) {
                return handlers[action.type](state, action);
            }
        }
        return state;
    }
};

const createNamespacer = namespace => {
    return action_type => {
        return `${namespace}:${action_type};`
    }
}

function getNamespace(action_type) {
    return action_type.split(":")[0];
}

export const authNameSpacer = createNamespacer('AUTH');
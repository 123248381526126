import React, { useState } from "react";
import { Modal } from "antd";
import { Button, notification } from "antd";
import { sendMessageData } from "../../api/auth";

const ContactUsModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const user = JSON.parse(localStorage.getItem("openbarcity_user"));

  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const sendMessage = async (event) => {
    let contactEmail;
    if (user) {
      contactEmail = user?.email;
    } else {
      contactEmail = email;
    }
    const data = { contactEmail: contactEmail, contactMessage: message };
    event.preventDefault();
    try {
      await sendMessageData(data);
      notification.success({
        message: "message sent successfully",
        placement: "bottomLeft",
        duration: 8,
      });
    } catch (error) {
      notification.error({
        message: "Failed to send message",
        placement: "bottomLeft",
        duration: 5,
      });
    } finally {
      setIsModalVisible(false);
      setEmail("");
      setMessage("");
    }
  };
  return (
    <div className="contactForm">
      <span onClick={showModal}>Contact Us</span>
      <Modal
        title="Contact Us"
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <form onSubmit={sendMessage}>
          {user === null ? (
            <div className="form-group">
              <label htmlFor="contactEmail" className="contact__label">
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Enter your Email"
                  className="contact__input"
                  value={email}
                  required
                />
              </label>
            </div>
          ) : (
            ""
          )}

          <div className="form-group">
            <textarea
              style={{
                padding: "5px 8px",
                border: "1px solid lightgray",
                width: '100%'
              }}
              name="message"
              id="message"
              rows="5"
              placeholder="Leave your message"
              onChange={(event) => setMessage(event.target.value)}
              value={message}
              required
            ></textarea>
          </div>
          <div className="button__group">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ContactUsModal;

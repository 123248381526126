import React from "react";
import { FcCheckmark } from "react-icons/fc";
import moment from 'moment';

const EventItem = ({ item }) => {
  const start_date = moment(item.start_date, "DD-MM-YYYY hh-mm-ss").format("LLL").toString();
  let end_date = "";
  if (item.category !== "4") {
    end_date = moment(item.end_date, "DD-MM-YYYY hh-mm-ss").format("LLL").toString();
  }
  
  return (
    // <div className="col-lg-4 column-td-6">
    <div className="event-card-item">
      {/* <Link to={`/events/${item.id}`} className="card-image-wrap"> */}
        <div className="card-image card-image-wrap">
          <img src={item.images} className="card__img" alt="Place" />
          <span
            className={
              item.event_price === "0" ? "badge" : "badge badge-closed"
            }
          >
            {item.event_price === "0" ? "Free" : `$${item.event_price}`}
          </span>
          {item.is_payment_done && (
            <span
              className="badge-toggle"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Purchased"
            >
              <FcCheckmark />
            </span>
          )}
        </div>
      {/* </Link> */}
      <div className="card-content-wrap">
        <div className="event-card-content">
            <h4 className="card-title">{item.name}</h4>
            <p className="card-sub">{item.pre_description}</p>
            <p className="card-sub">Drinks: {item.unit_drinks}</p>
            <p className="card-sub">From: {start_date}</p>
            {item.category !== "4" && (
              <p className="card-sub">To: {end_date}</p>
            )}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default EventItem;

import api from 'api';

export const checkTicketAvailability = (data) => {
    return api({
        method: 'POST',
        url: `checkTicketAvailability/`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        },
        data
    })
}

export const createPaymentIntent = (data) => {
    return api({
        method: 'POST',
        url: `createPaymentIntent/`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        },
        data
    });
};

export const confirmPaymentByStripe = (data) => {
    return api({
        method: 'POST',
        url: 'confirmPayment/',
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        },
        data
    })
}

export const generatePaymentLink = (data) => {
    return api({
        method: 'POST',
        url: `generatPaymentLink/?from=web`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        },
        data,
    })
}

export const addParticipant = (data) => {
    return api({
        method: 'POST',
        url: `addParticipants/`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        },
        data,
    })
}

export const checkPaymentForAgent = (data) => {
    return api({
        method: 'POST',
        url: `check-payment/`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        },
        data,
    })
}

export const recordPayByAgent = (data) => {
    return api({
        method: 'POST',
        url: `record-agent/`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        },
        data,
    })
}
/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Saturday 24, Feb 2024
 * File: Detail.jsx
 * Dir: frontend/src/pages/ecommarce
 * Project: openbarcity
 */

import React from "react";
import "../../assets/css/ecommarce.css";
import GeneralHeader from "components/common/GeneralHeader";
import Footer from "components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import arrow from "../../assets/images/arrow.png";
import {Link} from "react-router-dom";
import moment from "moment";

const Detail = (props) => {
    console.log("Detail|state: ",props.location.state);
    const {meal} = props.location.state;
    const start_date = moment(meal.start_date, "YYYY-MM-DD").format("MMM DD, YYYY").toString();
    let end_date = "N/A";
    if(meal.end_date){
        end_date = moment(meal.end_date, "YYYY-MM-DD").format("MMM DD, YYYY").toString();
    }

    return (
        <main className="List-map-view2 background-city">
            <GeneralHeader/>
            <section className="cta-area  column-sm-center padding-top-120px">
                <div className="container center">
                    <div className={`col-8 col-md-12`}>
                        <div className="card">
                            <div className="card-image" style={{height: 'auto', width: 'auto'}}>
                                <img alt={meal.name} className="detail-img" src={`${meal.image}`}/>
                            </div>
                            <div className="card-content">
                                <h4 className="card-title">{meal.name}</h4>
                                <p className="truncate" style={{width:'100%'}}>{meal.description}</p>
                            </div>

                            <div className="card-action" style={{fontSize: '1rem'}}>
                                <div style={{fontSize: '1rem'}}>
                                    <span>From</span>
                                    {start_date}
                                </div>
                                <div>
                                    <img className="img-responsive" src={arrow} alt="Arrow"/>
                                </div>
                                <div>
                                    <span>To</span>
                                    {end_date}
                                </div>
                            </div>
                            <div className="card-cta-section">
                                <Link
                                    to={{
                                        pathname: `/details/${meal.id}`,
                                        state: {meal}
                                    }}  // Add a dynamic link to the DetailPage with the event id
                                    className="btn-solid-lg-outline page-scroll"
                                >
                                    Book
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
            <ScrollTopBtn/>
        </main>
    );
};

export default Detail;
import React, { useState, useEffect } from "react";
import GeneralHeader from "components/common/GeneralHeader";
import Footer from "components/common/footer/Footer";
import ScrollTopBtn from "components/common/ScrollTopBtn";
import EventGrid from "components/events/grid";
import GenericHeader from "components/common/GenericHeader";
import { getEvents, getFilteredEvents } from "api/event";
import { useDispatch } from "react-redux";
import { clearStorage } from "lib/utils";

const Events = () => {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  useEffect(() => {
    async function getData() {
      try {
        const res = await getEvents();
        setEvents(res.data.response);
      } catch (error) {
        if (error.response.status === 401) {
          clearStorage(dispatch);
        }
      }
    }
    getData();
  }, [dispatch]);

  const filterEvents = (category) => {
    async function getData() {
      try {
        const res = await getFilteredEvents(category);
        setEvents(res.data.response);
      } catch (error) {
        if (error.response.status === 401) {
          clearStorage(dispatch);
        }
      }
    }
    getData();
  };

  return (
    <main className="List-map-view2 List-map-view2-vh">
      {/* Header */}
      <GeneralHeader />

      {/* Event Grid */}
      <section className="card-area padding-top-120px padding-bottom-100px main-flex-vh">
        {events && events.length > 0 ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <GenericHeader
                  total={events.length}
                  filterEvents={filterEvents}
                />
              </div>
            </div>

            <div className="row">
              <div className="events-container">
                <EventGrid events={events} />
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center padding-top-40px">
            {/* {<h1>Oops! Events not found.</h1>} */}
            <div className="lds-dual-ring"></div>
          </div>
        )}
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
};

export default Events;

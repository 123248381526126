import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { authNameSpacer } from "lib/reducers";
import { useDispatch, useSelector } from "react-redux";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import TouchAppOutlinedIcon from "@material-ui/icons/TouchAppOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import PersonIcon from "@material-ui/icons/Person";
import CreditCardIcon from "@material-ui/icons/CreditCard";

export default function Navbar() {
  const dispatch = useDispatch();
  const [navOpen, setNavOpen] = useState(false);
  const token = localStorage.getItem("openbarcity_token");
  const influenceToken = localStorage.getItem("openbarcity_influence");
  const barToken = localStorage.getItem("openbarcity_bar");
  const hotelUserToken = localStorage.getItem("openbarcity_hotel_user_id");
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isInfluenceLoggedIn = useSelector((state) => state.auth.isInfluenceLoggedIn);
  const isBarLoggedIn = useSelector((state) => state.auth.isBarLoggedIn);
  const isHotelUserLoggedin = useSelector((state) => state.auth.isHotelUserLoggedin);

  useEffect(() => {
    let isCancelled = false;
    // update global states - isLoggedIn and token as soon as the user is logged in
    async function updateAuth() {
      if (token !== null) {
        if (!isLoggedIn) {
          if (!isCancelled) {
            dispatch({
              type: authNameSpacer("SET_LOGGED_IN"),
              payload: {
                isLoggedIn: true,
                token,
              },
            });
          }
        }
      }
      if (influenceToken !== null) {
        if (!isInfluenceLoggedIn) {
          dispatch({
            type: authNameSpacer("SET_INFLUENCE_LOGGED_IN"),
            payload: {
              isInfluenceLoggedIn: true
            },
          });
        }
      }
      if (barToken !== null) {
        if (!isBarLoggedIn) {
          dispatch({
            type: authNameSpacer("SET_BAR_LOGGED_IN"),
            payload: {
              isBarLoggedIn: true
            }
          })
        }
      }
      if (hotelUserToken !== null) {
        if (!isHotelUserLoggedin) {
          dispatch({
            type: authNameSpacer("SET_HOTEL_USER_LOGGED_IN"),
            payload: {
              isHotelUserLoggedin: true
            }
          })
        }
      }
    }
    updateAuth();
    return () => {
      isCancelled = true;
    };
  }, [token, dispatch, isLoggedIn, isInfluenceLoggedIn, influenceToken, barToken, isBarLoggedIn, isHotelUserLoggedin, hotelUserToken]);

  $(document).on("click", ".side-menu-ul li", function () {
    $(".side-menu-ul li").removeClass("active");
    $(this).toggleClass("active");
  });
  $(document).on("click", ".side-menu-ul li.active", function () {
    $(".side-menu-ul li.active").removeClass("active");
  });

  return (
    <>
      <div className="main-menu-content">
        <nav>
          <ul>
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/restaurant-week">Restaurant Week</Link>
            </li>
            <li>
              <Link to="/hotels">Hotels</Link>
            </li>
            <li>
              <Link to="/bar-restaurants">Bar/Restaurants</Link>
            </li>
            <li>
              <Link to="/sustainable-tourism">Sustainable Tourism</Link>
            </li>
{/*            <li>
              <Link to="/hotel/meals">Hotels</Link>
            </li>*/}
          </ul>
        </nav>
      </div>
      <div className="side-menu-open" onClick={() => setNavOpen(!navOpen)}>
        <span className="menu__bar"></span>
        <span className="menu__bar"></span>
        <span className="menu__bar"></span>
      </div>
      <div
        className={navOpen ? "side-nav-container active" : "side-nav-container"}
      >
        <div className="humburger-menu">
          <div
            className="humburger-menu-lines side-menu-close"
            onClick={() => setNavOpen(!navOpen)}
          ></div>
        </div>
        <div className="side-menu-wrap">
          <ul className="side-menu-ul">
            <li>
              <Link to="/">
                <HomeRoundedIcon className="side-menu-li__icon" /> home
              </Link>
            </li>
            <li>
              <Link to="/what-is-it">
                {" "}
                <ContactSupportOutlinedIcon className="side-menu-li__icon" />
                what is it
              </Link>
            </li>
            <li>
              <Link to="/how-to">
                <TouchAppOutlinedIcon className="side-menu-li__icon" />
                How to use
              </Link>
            </li>
            <li>
              <Link to="/destinations">
                <LocationOnOutlinedIcon className="side-menu-li__icon" />
                destinations
              </Link>
            </li>
            <li>
              <Link to="/covid-19">
                <AssignmentOutlinedIcon className="side-menu-li__icon" />
                covid-19
              </Link>
            </li>
            {isLoggedIn && <li>
              <Link to="/user/events">
                <CreditCardIcon className="side-menu-li__icon" />
                Events
              </Link>
            </li>}
            <li>
              {isLoggedIn && <Link to="/profile">
                <PersonIcon className="side-menu-li__icon" />
                User Profile
              </Link>}
              {isInfluenceLoggedIn && <Link to="/influence">
                <PersonIcon className="side-menu-li__icon" />
                Influence Profile
              </Link>}
              {isBarLoggedIn && <Link to="/bar">
                <PersonIcon className="side-menu-li__icon" />
                Bar Profile
              </Link>}
              {isHotelUserLoggedin && <Link to="/influence">
              <PersonIcon className="side-menu-li__icon" />
               Profile
              </Link>}
            </li>
          </ul>
          {!isLoggedIn && !isInfluenceLoggedIn && !isBarLoggedIn && !isHotelUserLoggedin && (
            <div className="side-nav-button">
              <Link to="/login" className="theme-btn">
                LOGIN
              </Link>
              <Link to="/sign-up" className="theme-btn">
                SIGN UP
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import React, {Component} from 'react';

class Copyright extends Component {
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="copy-right margin-top-20px">
                            <p className="copy__desc">
                                &copy; Copyright Openbarcity.
                                {/*OpenBarCity is an interactive application allowing you to have a self-guided craft
                                cocktail tour for those in search of a Drinkable history of America.*/}
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Copyright;
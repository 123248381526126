import React from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import SectionsHeading from "../../components/common/SectionsHeading";
import Title from "../../components/title/Title";

// one purchased = one donated
const Op = () => {
  const descriptions = [
    {
      text: `In our One Purchased = One Donated mission, every meal purchased for a Restaurant Week
      event through our app, our sponsors donate a meal to a person in need.`
    },
    {
      text: `Lower priced meals offered during Restaurant Week events makes donating meals from participating restaurants, for 
      food insecure individuals a much easier problem to solve.`
    },
    {
      text: `Master craft spirits is our key sponsor please check them out at mastercraftspirits.com and
      show them as much appreciation as you can for their generous offering!`
    },
    {
      text: `Local hotels in our group have become partners too!. Since not every hotel will have bar or restaurant services
       the rest of the city does! Ask about our OBC rewards program and enjoy the trip you've always wanted
       when you use OBC app as an all-inclusive add-on.`
    },
    {
      text: 'It is the perfect travel companion!'
    }
  ]
  return (
    <main className="List-map-view2 background-city">
      {/* Header */}
      <GeneralHeader />

      {/* How it work 1 */}
      <section className="hiw-area choose-area padding-top-120px padding-bottom-80px after-none">
        <div className="container">
          {/* <div className="row section-title-width text-center"> */}
          <div className="row align-items-center">
            <div className="col-lg-12" style={{ padding: "0" }}>
              <Title
                title="One Purchased = One Donated"
                subTitle=""
              />
            </div>
          </div>
          <div className="row text-center">
            <SectionsHeading
              headerClass="background-city-shadow"
            >
              {descriptions.map((description) => {
                return (
                  <p className="sec__desc">{description.text}</p>
                )
              })}
              <br />
              <p className="sec__desc">
                *If you are a visitor to any of these cities and you would like to receive deals and
                gift incentives please inquire through your guest services department directly. We
                do not offer hotel or vacation stay incentives directly to the public. 
              </p>
            </SectionsHeading>
          </div>

        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
};

export default Op;

import React from "react";
// import { useLocation } from "react-router-dom";
import Copyright from "./Copyright";
import ContactUsModal from "../../contactUsModal/contactUsModal";

function Footer() {
  return (
    <>
      <section
        className={
          "footer-area section-bg padding-bottom-20px padding-top-60px footer-bg"
        }
      >
        <div className="container">
          <h2 className="footer__title">Contact us</h2>
          <p className="contact-p">
            {/*We strongly suggest cocktail lovers sign up and get started with the
            amazing craft cocktail tours. If you have any questions about the
            app,*/}
          </p>
          <ContactUsModal />
          {/* Copyright */}
          <Copyright />
        </div>
      </section>
    </>
  );
}

export default Footer;

import React, {Component} from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import LoginBox from "../components/other/account/LoginBox";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from '../assets/images/bread-bg.jpg';

class OldLogin extends Component {
    state = {
        breadcrumbimg: breadcrumbimg,
    }

    render() {
        return (
            <main className="login-page">
                {/* Header */}
                <GeneralHeader/>

                <section className="form-shared padding-top-120px padding-bottom-100px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                                <LoginBox title="Login to your account"/>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Footer */}
                <Footer/>

                <ScrollTopBtn/>

            </main>
        );
    }
}

export default OldLogin;
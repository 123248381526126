import React, { Component } from "react";
import { IoMdRestaurant } from "react-icons/io";
import { FaCocktail, FaConnectdevelop } from "react-icons/fa";

class InfoBoxOne extends Component {
  states = {
    items: [
      {
        id: "1",
        icon: <IoMdRestaurant />,
        description:
          "Support OnePurchased=OneDonated",
      },
      {
        id: "2",
        icon: <FaCocktail />,
        description: `100% of donations through the app are given to unger relief centers in the city`,
      },
      {
        id: "3",
        icon: <FaConnectdevelop />,
        description:
          "Sustainable Tourism is in your city",
      },
    ],
  };
  render() {
    return (
      <>
        {this.states.items.map((item) => {
          return (
            <div className="col-lg-4 column-td-6 phone-width" key={item.id}>
              <div className="icon-box">
                <div className="info-icon">
                  {item.icon}
                  {/* <span className="info-number">{item.id}</span> */}
                </div>
                <div className="info-content">
                  <p className="info__desc">{item.description}</p>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default InfoBoxOne;

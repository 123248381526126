import React from "react";
/*import {Link} from "react-router-dom";
import {FaCocktail} from "react-icons/fa";*/
import GeneralHeader from "components/common/GeneralHeader";
import BannerOne from "components/banner/banner1/BannerOne";
import Footer from "components/common/footer/Footer";
import ScrollTopBtn from "components/common/ScrollTopBtn";
import PlaceOne from "components/places/PlaceOne";
/*import SectionsHeading from "components/common/SectionsHeading";
import Testimonial from "components/sliders/Testimonial";
import Button from "components/common/Button";*/
// import {useSelector} from "react-redux";

const Home = () => {
    // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    return (
        <main className="home-1">
            {/* Header */}
            <GeneralHeader/>

            {/* Hero Banner */}
            <BannerOne/>

            {/* Sliders */}
            <section className="card-area text-center padding-bottom-100px">
                {/* <div className="container"> */}
                <PlaceOne/>
                {/* </div> */}
            </section>
            <section className="cta-area  column-sm-center padding-top-120px">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 bg-white p-5 rounded">
                            <article>
                                <h2 className="text-center mb-3">OUR MISSION:1 MILLION MEALS DONATED TO HUNGER Relief
                                    centers</h2>
                                <p className="mb-3">
                                    Food insecurity, is when a person’s ability to secure enough food is in
                                    danger, often due to lack of resources or income. Since an alarming rise of
                                    people in our local communities do not know where their next meal is going
                                    to come from, we responded using the tools and the partnerships available
                                    to us to answer the call. We developed the <strong className="text-success">OnePurchased=OneDonated </strong>
                                    initiative and set an ambitious goal of 1 million meals donated that we are
                                    excited to reach.
                                </p>
                                <p>
                                    Our alliance with hotels, local bars and restaurants, and Restaurant Week
                                    organizations we have the ability to fund our mission of 1 Million meals donated
                                    to hunger relief centers by December 2025. It is our hope that local
                                    communities will be uplifted and the combination of businesses involved will
                                    foster better relationships and ultimately stronger businesses for many years to
                                    come.
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            {/* Testimonial */}
            {/*<section className="testimonial-area padding-top-10px padding-bottom-100px text-center">
        <div className="container">
          <div className="row text-center justify-content-row">
            <SectionsHeading
              title="Testimonials"
              desc=""
              headerClass="home-section-heading"
            />
          </div>
          <div className="row">
            <div className="col-lg-8 mx-auto mt-4">
              <Testimonial />
            </div>
          </div>
        </div>
      </section>*/}

            {/** Social Media Ad */}
            {/*<section className="text-center">
        <div className="container">
          {isLoggedIn && <Button text="Events" url="/buy-now" className="btn__withborder">
            <FaCocktail />
          </Button>}
          <div className="section-heading home-section-heading">
            <h2 className="sec__title margin-bottom-20px margin-top-30px">
              Social Media Ad
            </h2>
          </div>
          {!isLoggedIn && (
            <h5 className="margin-top-30px">
              <Link to="/sign-up" className="signup-to-join">
                Sign up to join the USA’s best self-guided craft cocktail tour
                app
              </Link>
            </h5>
          )}
          <p className="contact-p margin-top-30px margin-bottom-30px">
            This app brings you amazing cocktails and fun facts of great stories
            behind each establishment, each city and state and culture you may
            not have otherwise experienced on your own.
          </p>
        </div>
      </section>*/}

            {/* Footer */}
            <Footer/>

            <ScrollTopBtn/>
        </main>
    );
};

export default Home;

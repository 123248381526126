import {Formik, Form} from 'formik';
import React, {useState} from 'react';
import {AiOutlineUser} from 'react-icons/ai';
import {FiLock} from 'react-icons/fi';
import {Link, useHistory} from "react-router-dom";
import * as Yup from 'yup';
import {Button, notification} from 'antd';
import {signin, influenceSignin} from 'api/auth';
import {setHeaders, setInfluenceHeaders} from 'lib/utils';
import BarLogin from './BarLogin';

function LoginBox({title}) {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [influenceLoading, setInfluenceLoading] = useState(false);

    const loginInitial = {
        'email': '',
        'password': '',
    }
    const influenceLoginInitial = {
        'username': '',
        'password': ''
    }
    const loginValidationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Required'),
    })
    const influenceValidationSchema = Yup.object().shape({
        username: Yup.string().required('Required'),
        password: Yup.string().required('Required')
    })
    const onSubmit = async (values, {setFieldError}) => {
        try {
            setLoading(true);
            // login stuff
            values.username = values.email;
            let formData = new FormData();
            for (const key in values) {
                formData.append(key, values[key]);
            }
            formData.delete('email');
            console.log(formData);
            const res = await signin(formData);
            setHeaders(res, history, '/');
        } catch (error) {
            let description = "";
            if (error.response.status === 400)
                description = Object.values(error.response.data).join();
            console.log(description);
            notification.error({
                message: "Signin failed.",
                description,
                placement: "bottomLeft",
                duration: 8,
                bottom: 100
            });
        } finally {
            setLoading(false);
        }
    };
    const onInfluenceSubmit = async (values, {setFieldError}) => {
        try {
            setInfluenceLoading(true);
            // login stuff
            let formData = new FormData();
            for (const key in values) {
                formData.append(key, values[key]);
            }
            const res = await influenceSignin(formData);
            setInfluenceHeaders(res, history, '/influence')
        } catch (error) {
            let description = "";
            if (error.response.status === 400)
                description = Object.values(error.response.data).join();
            notification.error({
                message: "Influence Signin failed.",
                description,
                placement: "bottomLeft",
                duration: 8,
                bottom: 100
            });
        } finally {
            setInfluenceLoading(false);
        }
    };

    return (
        <>
            <div className="billing-form-item mb-0">
                <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
                    <h3 className="widget-title font-size-28 pb-0">
                        {title}
                    </h3>
                </div>
                <div className="billing-content">
                    <div className="contact-form-action">
                        <Formik
                            initialValues={loginInitial}
                            validationSchema={loginValidationSchema}
                            onSubmit={onSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                              }) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="input-box">
                                                <label className="label-text">Email</label>
                                                <div className="form-group">
                          <span className="form-icon">
                            <AiOutlineUser/>
                          </span>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder="Enter email"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <div className="ant-form-item-explain">
                                                            {errors.email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="input-box">
                                                <label className="label-text">Password</label>
                                                <div className="form-group">
                          <span className="form-icon">
                            <FiLock/>
                          </span>
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        name="password"
                                                        placeholder="Enter password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.password}
                                                    />
                                                    {errors.password && touched.password ? (
                                                        <div className="ant-form-item-explain">
                                                            {errors.password}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <div
                                                    className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                                                    <div>
                                                    </div>
                                                    <div>
                                                        <Link to="/recover" className="color-text font-weight-medium">
                                                            Forgot password?
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="btn-box margin-top-20px margin-bottom-20px">
                                                <Button
                                                    type="primary"
                                                    className="width100"
                                                    onClick={() => {
                                                        handleSubmit();
                                                    }}
                                                    loading={loading}
                                                >
                                                    Login now
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <p className="font-weight-medium">Not a member? <Link to="/sign-up"
                                                                                                  className="color-text"> Register</Link>
                                            </p>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <div className="billing-form-item mb-0">
                <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
                    <h3 className="widget-title font-size-28 pb-0">
                        Affiliate Login
                    </h3>
                </div>
                <div className="billing-content">
                    <div className="contact-form-action">
                        <Formik
                            initialValues={influenceLoginInitial}
                            validationSchema={influenceValidationSchema}
                            onSubmit={onInfluenceSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                              }) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="input-box">
                                                <label className="label-text">Username</label>
                                                <div className="form-group">
                          <span className="form-icon">
                            <AiOutlineUser/>
                          </span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="username"
                                                        placeholder="Enter username"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.username}
                                                    />
                                                    {errors.username && touched.username ? (
                                                        <div className="ant-form-item-explain">
                                                            {errors.username}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="input-box">
                                                <label className="label-text">Password</label>
                                                <div className="form-group">
                          <span className="form-icon">
                            <FiLock/>
                          </span>
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        name="password"
                                                        placeholder="Enter password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.password}
                                                    />
                                                    {errors.password && touched.password ? (
                                                        <div className="ant-form-item-explain">
                                                            {errors.password}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-12">
                      <div className="form-group">
                        <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                          <div>
                          </div>
                          <div>
                            <Link to="/recover" className="color-text font-weight-medium">
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                                        <div className="col-lg-12">
                                            <div className="btn-box margin-top-20px margin-bottom-20px">
                                                <Button
                                                    type="primary"
                                                    className="width100"
                                                    onClick={() => {
                                                        handleSubmit();
                                                    }}
                                                    loading={influenceLoading}
                                                >
                                                    Login now
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <BarLogin/>
        </>
    );
}

export default LoginBox;
import React, { Component } from "react";
import BannerOneHeroHeading from "./BannerOneHeroHeading";
import InfoBoxOne from "components/other/infoboxes/infobox1/InfoBoxOne";

export default class BannerOne extends Component {
  render() {
    return (
      <>
        <section className="hero-wrapper">
          <div className="hero-overlay"></div>
          <div className="container">
            <div className="row justify-content-row">
              <div className="col-lg-12">
                {/* Banner One Hero Heading */}
                <BannerOneHeroHeading />
                <div className="row padding-top-100px justify-content-row">
                  <InfoBoxOne />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

import React from "react";
import GeneralHeader from "components/common/GeneralHeader";
import Footer from "components/common/footer/Footer";
import ScrollTopBtn from "components/common/ScrollTopBtn";
import { useParams } from "react-router-dom";
// Stripe import
import {
    Elements,
} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import CheckoutForm from './checkoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_vp7Tm3aaTw6oadBbRUpV7jQv00G4zBbTOr');

function StripeCard() {
    const { event_id, amount } = useParams();

    return (
    <main className="List-map-view2">
        {/* Header */}
        <GeneralHeader />

        <section className="single-listing-area padding-top-120px">
        <div className="container">
            <div className="row row-margin justify-between-row margin-top-40px">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                    <h3 className="text-center color-text margin-bottom-10px">$ {amount}</h3>
                    <h5 className="text-color-mid text-center margin-bottom-40px">
                        By placing your order, you agree to follow the rules and regulations of each bar or restaurant you visit and 
                        our <a href='/terms-of-use' target="_blank">conditions of use.</a>
                    </h5>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm 
                            event_id={event_id}
                            amount={amount}
                        />
                    </Elements>
                </div>
                <div className="col-lg-3"></div>
            </div>
        </div>        
        </section>

        {/* Footer */}
        <Footer />

        <ScrollTopBtn />
    </main>
    );
}

export default StripeCard;

import { createReducer, authNameSpacer as nameSpacer } from "lib/reducers";

let token = localStorage.openbarcity_token !== undefined ? localStorage.openbarcity_token : null;
let influenceToken = localStorage.openbarcity_influence !== undefined ? localStorage.openbarcity_influence : null;
let barToken = localStorage.openbarcity_bar !== undefined ? localStorage.openbarcity_bar : null;
let hotelUserToken = localStorage.openbarcity_hotel_user_id !== undefined ? localStorage.openbarcity_hotel_user_id : null;

const initialState = {
    isLoggedIn: token !== null,
    isInfluenceLoggedIn: influenceToken !== null,
    isBarLoggedIn: barToken !== null,
    token: token,
    isHotelUserLoggedin: hotelUserToken !== null,
}

const handlers = {
    [nameSpacer('SET_LOGGED_IN')]: (state, action) => {
        return {
            ...state,
            isLoggedIn: action.payload.isLoggedIn,
            token: action.payload.token,
        }
    },
    [nameSpacer('SET_INFLUENCE_LOGGED_IN')]: (state, action) => {
        return {
            ...state,
            isInfluenceLoggedIn: action.payload.isInfluenceLoggedIn,
        }
    },
    [nameSpacer('SET_BAR_LOGGED_IN')]: (state, action) => {
        return {
            ...state,
            isBarLoggedIn: action.payload.isBarLoggedIn
        }
    },
    [nameSpacer('SET_HOTEL_USER_LOGGED_IN')]: (state, action) => {
            return {
                ...state,
                isHotelUserLoggedin: action.payload.isHotelUserLoggedin
            }
        }
}

const authReducer = createReducer(initialState, handlers, ['AUTH']);

export default authReducer;
import api from "api";

export const signup = (data) => {
  return api({
    method: "POST",
    url: `userSignUp/`,
    data,
  });
};

export const listUserEmails = (data) => {
  return api({
    'method': "POST",
    url: `listUserEmails/`,
    data,
  });
};

export const signin = (data) => {
  return api({
    method: "POST",
    url: `userLogin/?isBrowser=True`,
    data,
  });
};

export const influenceSignin = (data) => {
  return api({
    method: "POST",
    url: `influenceLogin/`,
    data,
  });
};

export const barSignin = (data) => {
  return api({
    method: "POST",
    url: `barLogin/`,
    data
  });
};

export const hotelSignin = (data) => {
  return api({
    method: "POST",
    url: `hotel/login/`,
    data
  });
};

export const getProfile = (user_id) => {
  return api({
    url: `userSignUp/${user_id}/`,
    headers: {
      Authorization: `${localStorage.getItem("openbarcity_token")}`,
    },
  });
};

export const updateProfile = (user_id, data) => {
    return api({
        method: 'PATCH',
        url: `userSignUp/${user_id}/`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        },
        data,
    })
}

export const updateInfluenceProfile = (influence_id, data) => {
  return api({
    method: 'PUT',
    url: `updateInfluenceProfile/${influence_id}/`,
    headers: {
      'Authorization': `${localStorage.getItem('openbarcity_influence')}`
    },
    data
  })
}

export const updateBarProfile = (bar_id, data) => {
  return api({
    method: 'PUT',
    url: `updateBarProfile/${bar_id}/`,
    headers: {
      'Authorization': `${localStorage.getItem('openbarcity_bar')}`
    },
    data
  })
};


// this api is to send the email with the link for resetting password
export const forgotPassword = (data) => {
  return api({
    method: "POST",
    url: `forgotPassword/`,
    data,
  });
};

// this api is to reset the password
export const updatePassword = (data) => {
  return api({
    method: "POST",
    url: `updatePassword/`,
    data,
  });
};

export const logout = () => {
  console.log(localStorage);
  return api({
    method: "POST",
    url: `userLogout/`,
    headers: {
      Authorization: `${localStorage.getItem("openbarcity_token")}`,
    },
  });
};

export const sendMessageData = (data) => {
  return api({
    method: "POST",
    url: `contactUs/`,
    data,
  });
};

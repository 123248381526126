import api from 'api';

export const getCities = () => {
    return api({
        url: `getCities/`,
    });
};

export const getBars = (sampleCase = '0') => {
    return api({
        url: `getBars/?sampleCase=${sampleCase}`,
    });
};
import React, { useState } from "react";
import GeneralHeader from "components/common/GeneralHeader";
import Footer from "components/common/footer/Footer";
import ScrollTopBtn from "components/common/ScrollTopBtn";
import UserSidebar from "components/other/account/UserSidebar";
// import TicketsGrid from "components/tickets/grid";
// import { getEvents } from "api/event";
// import { useDispatch } from "react-redux";
// import { clearStorage } from "lib/utils";

const Profile = () => {
  // const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("openbarcity_user"));
  const [fullName, setFullName] = useState(
    user.first_name + " " + user.last_name
  );
  // const [tickets, setTickets] = useState([]);
  // useEffect(() => {
  //   async function getData() {
  //     try {
  //       const res = await getEvents();
  //       setTickets(res.data.response.filter((e) => e.is_payment_done === 1));
  //     } catch (error) {
  //       if (error.response.status === 401) {
  //         clearStorage(dispatch);
  //       }
  //     }
  //   }
  //   getData();
  // }, [tickets.length, dispatch]);

  // let ticketsElement = "";

  // if (tickets && tickets.length > 0) {
  //   ticketsElement = (
  //     <div className="row two-clmn margin-top-35px">
  //       <div className="events-container">
  //         <TicketsGrid tickets={tickets} fullName={fullName} />
  //       </div>
  //     </div>
  //   );
  // } else if (tickets) {
  //   ticketsElement = <div className="margin-top-35px">Loading...</div>;
  // } else {
  //   ticketsElement = (
  //     <div className="margin-top-35px">There are no purchased tickets.</div>
  //   );
  // }

  return (
    <main className="List-map-view2 List-map-view2-vh">
      {/* Header */}
      <GeneralHeader />

      <section className="user-profile-area padding-top-120px padding-bottom-100px main-flex-vh">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="user-content">
                <UserSidebar
                  user={user}
                  fullName={fullName}
                  setFullName={setFullName}
                />
              </div>
            </div>
            {/* <div className="col-lg-8">
              <h3 className="widget-title">{fullName}'s Tickets</h3>
              <div className="title-shape"></div>
              {ticketsElement}
            </div> */}
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
};

export default Profile;

import React, { useRef, useEffect, Suspense, lazy } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import { Spinner } from "components/helpers/Loader";

// styles
import "./styles/common.scss";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Pages
import Home from "./pages/homes/Home";
import WhatIsIt from "./pages/whatIsIt";
import HowToUseApp from "./pages/howToUseApp";
import Op from "./pages/op";
import Covid19 from "./pages/covid19";
import Login from "./pages/Login";
import OldLogin from "./pages/OldLogin";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/forgotPassword/index";
import UpdatePassword from "./pages/forgotPassword/update";
import Profile from "./pages/profile";
import Events from "./pages/events";

import Ecommarce from "./pages/ecommarce/index";
import Detail from "./pages/ecommarce/Detail";

import RestaurantWeek from "./pages/restaurantWeek";
import Hotels from "./pages/hotels";
import BarRestaurants from "./pages/barRestaurants";
import SustainableTourism from "./pages/sustainableTourism";
import Checkout from "./pages/influence/checkout";

// import EventDetails from "./pages/events/detail";
import PaymentConfirmation from "./pages/payment/confirmation";
import StripeCard from './pages/events/stripeCard';
import Error from "./pages/Error";

// Private Route
import PrivateRoute from "components/helpers/privateRoute";

const Influence = lazy(() => import("./pages/influence"));
const InfluenceProfile = lazy(() => import("./pages/influence/profile"));
const Bar = lazy(() => import('./pages/bar/index'));
const BarQrPrint = lazy(() => import('./pages/bar/qrPrint'));
const PageLoadepstyle = {
    width: "48px",
    height: "40%",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
};
const PageLoader = () => <Spinner color={"#dc7f20"} style={PageLoadepstyle} />;

const App = () => {
    const childRef = useRef();
    let location = useLocation();

    useEffect(() => {
        document.body.classList.add("is-loaded");
        childRef.current.init();
    }, [location]);

    return (
        <Suspense fallback={<PageLoader />}>
            <ScrollReveal
                ref={childRef}
                children={() => (
                    <Switch>
                        <AppRoute path="/" component={Home} layout={LayoutDefault} exact />
                        <AppRoute path="/restaurant-week" component={RestaurantWeek} exact />
                        <AppRoute path="/hotels" component={Hotels} exact />
                        <AppRoute path="/bar-restaurants" component={BarRestaurants} exact />
                        <AppRoute path="/sustainable-tourism" component={SustainableTourism} exact />
                        <AppRoute path="/meals" component={Ecommarce} exact />

                        <AppRoute path="/what-is-it" component={WhatIsIt} exact />
                        <AppRoute path="/how-to" component={HowToUseApp} exact />
                        <AppRoute path="/op" component={Op} exact />
                        <AppRoute path="/covid-19" component={Covid19} exact />
                        <AppRoute path="/login" component={Login} exact />
                        <AppRoute path="/old/login" component={OldLogin} exact />
                        <AppRoute path="/sign-up" component={SignUp} exact />
                        <AppRoute path="/recover" component={ForgotPassword} exact />
                        <AppRoute
                            path="/update-password/:token"
                            component={UpdatePassword}
                            exact
                        />
                        <AppRoute path="/purchase/:unique" component={Influence} exact />
                        <AppRoute path="/details/:mealId" component={Detail} />
                        <PrivateRoute path="/influence" component={InfluenceProfile} exact />
                        <AppRoute path="/influence/payment" component={Checkout} exact />
                        <PrivateRoute path="/bar" component={Bar} exact />
                        <PrivateRoute path="/bar-qr-print" component={BarQrPrint} exact />
                        <PrivateRoute path="/profile" component={Profile} exact />
                        <PrivateRoute path="/user/events" component={Events} exact />
                        {/* <PrivateRoute
              path="/events/:event_id"
              component={EventDetails}
              exact
            /> */}
                        <PrivateRoute
                            path="/payment-confirmation"
                            component={PaymentConfirmation}
                            exact
                        />
                        <PrivateRoute
                            path="/events/:event_id/:amount"
                            component={StripeCard}
                            exact
                        />
                        <AppRoute component={Error} />
                    </Switch>
                )}
            />
        </Suspense>
    );
};

export default App;

/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Thursday 01, Feb 2024
 * File: TabNavs.js
 * Dir: frontend/src/pages
 * Project: openbarcity
 */

import React from "react";

const TabNavs = ({ activeTab, handleTabClick }) => {

    return (
        <nav className="tab-navs">
            <div className="nav nav-tabs" role="tablist">
                <button
                    className={`nav-link ${activeTab === "nav-account" ? "active" : ""}`}
                    onClick={(e) => handleTabClick("nav-account", e)}
                >
                    Account
                </button>
                <button
                    className={`nav-link ${activeTab === "nav-affiliate" ? "active" : ""}`}
                    onClick={(e) => handleTabClick("nav-affiliate", e)}
                >
                    Hotel
                </button>
                <button
                    className={`nav-link ${activeTab === "nav-bar" ? "active" : ""}`}
                    onClick={(e) => handleTabClick("nav-bar", e)}
                >
                    Bar
                </button>
{/*                <button
                    className={`nav-link ${activeTab === "nav-host" ? "active" : ""}`}
                    onClick={(e) => handleTabClick("nav-host", e)}
                >
                    Hotel
                </button>*/}
            </div>
        </nav>
    );
};

export default TabNavs;

import React, { useState } from "react";
import {Modal, notification} from 'antd';
// Stripe import
import {
    useStripe, 
    useElements,
    CardElement,
} from '@stripe/react-stripe-js'
import { Button } from "antd";
import { confirmPaymentByStripe, createPaymentIntent } from "api/payment";
import { clearStorage } from "lib/utils";
import { useDispatch } from "react-redux";
// styles
import "styles/common.scss";
import { useHistory } from "react-router";

export default function CheckoutForm({amount, event_id}) {
    const dispatch = useDispatch()
    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();

    const [showModal, setShowModal] = useState(false);
    const [brand, setBrand] = useState("")
    const [last4, setLast4] = useState("")
    const [loading, setLoading] = useState(false)
    const [payLoading, setPayLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState("") 

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);
        } else {
            const card = paymentMethod.card
            setBrand(card.brand)
            setLast4(card.last4)
            createPaymentIntentFunc()
        }
    };

    const showErrorNotification = (error) => {
        if (error.response.status === 401) {
            clearStorage(dispatch);
        }
        let description = "";
        if (error.response.status === 400)
            description = Object.values(error.response.data).join();
        notification.error({
            message: "Error!",
            description,
            placement: "bottomLeft",
            duration: 8,
            bottom: 100,
        });
    }

    const createPaymentIntentFunc = async () => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append("eventId", event_id);
            formData.append("amount", amount * 100)
            const res = await createPaymentIntent(formData);
            const result = res.data.success
            if (result === 1) {
                const client_Secret = res.data.response.client_secret;
                setClientSecret(client_Secret)
                setShowModal(true);
            } else if (result === 2) {
                    notification.info({
                        message: res.data.message,
                        placement: "topRight",
                        duration: 8,
                        top: 120,
                });
            }
        } catch (error) {
            showErrorNotification(error);
        } finally {
            setLoading(false);
        }
    }

    const handleProceedPayment = async () => {
        setPayLoading(true);
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        })
        if (payload.error) {
            setPayLoading(false);
            setShowModal(false);
            notification.error({
                message: "Check this error!",
                description: `${payload.error.message}`,
                placement: "bottomLeft",
                duration: 8,
                bottom: 100,
            });
        } else {
            try {
                let formData = new FormData();
                formData.append("paymentIntentId", payload.paymentIntent.id);
                formData.append("eventId", event_id)
                const res = await confirmPaymentByStripe(formData);
                const result = res.data.success
                if (result === 1) {
                    notification.success({
                        message: "Payment Successfully Done.",
                        placement: "topRight",
                        duration: 8,
                        top: 120,
                    });
                    history.push("/profile");  
                } else if (result === 2) {
                        notification.info({
                            message: res.data.message,
                            placement: "topRight",
                            duration: 8,
                            top: 120,
                    });
                }
            } catch (error) {
                showErrorNotification(error);
            } finally {
                setPayLoading(false)
            }    
        }
    }

    return (
        <>
            <CardElement 
                options={{
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                    hidePostalCode: true
                }}
            />
            <div className="text-center">
                <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    className="margin-top-50px"
                    loading={loading}
                    disabled={!stripe}
                >
                    Confirm Payment
                </Button>
            </div>
            <Modal
                title="Review Order"
                width="300px"
                centered
                visible={showModal}
                footer={null}
                onCancel={() => setShowModal(false)}
            >
                <div style={{clear: "both"}}>
                    <p className="alignleft">Order</p>
                    <p className="alignright">$ {amount}</p>
                </div>
                <div style={{clear: "both"}}>
                    <p className="alignleft">
                        Pay with
                    </p>
                    <p className="alignright">
                        {brand} ****{last4}
                    </p>
                </div>
                <br/>
                <br/>
                <div className="color-text-2 margin-bottom-10px">
                    <Button
                        className="author__verified-badge"
                        type="primary"
                        loading={payLoading}
                        onClick={() => handleProceedPayment()}
                    >
                        Confirm Order
                    </Button>
                </div>
            </Modal>
        </>
    );
}

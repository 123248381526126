import React, { useState } from 'react';
import {Link} from "react-router-dom";
import { FaRegEnvelope } from 'react-icons/fa'
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {notification, Button} from 'antd';
import { forgotPassword } from 'api/auth';

function RecoverPassBox() {
    const [loading, setLoading] = useState(false);
    const initial = {
        'email': ''
    }
    const schema = Yup.object().shape({
        'email': Yup.string().email('Invalid email').required('Required'),
    })
    const onSubmit = async (values) => {
        try {
            setLoading(true);
            let formData = new FormData();
            for (const key in values) {
                formData.append(key, values[key]);
            }
            await forgotPassword(formData);
            notification.success({
                message: 'Email was sent!',
                placement: "bottomLeft",
                duration: 8,
                bottom: 100
            })
        } catch (error) {
            let description = "";
            if (error.response.status === 400)
                description = Object.values(error.response.data).join();
            notification.error({
                message: "Email not sent. Please check your email.",
                description,
                placement: "bottomLeft",
                duration: 8,
                bottom: 100
            });
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <section className="form-shared padding-top-120px padding-bottom-100px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="billing-form-item mb-0">
                                <div className="billing-title-wrap">
                                    <h3 className="widget-title font-size-28">Recover Password!</h3>
                                    <p className="font-size-16 font-weight-medium">Enter the email of your account
                                        to reset password.
                                        Then you will receive a link to email to reset the password.
                                    </p>
                                </div>
                                <div className="billing-content">
                                    <div className="contact-form-action">
                                        <Formik
                                            initialValues={initial}
                                            validationSchema={schema}
                                            onSubmit={onSubmit}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                            }) => (
                                                <Form>
                                                    <div className="input-box">
                                                        <label className="label-text">Your Email</label>
                                                        <div className="form-group">
                                                            <span className="la form-icon"><FaRegEnvelope /></span>
                                                            <input 
                                                                className="form-control" 
                                                                type="email" 
                                                                name="email" 
                                                                value={values.email} 
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                placeholder="Enter email address" />
                                                            {errors.email && touched.email ? (
                                                                <div className="ant-form-item-explain">
                                                                    {errors.email}
                                                                </div>
                                                            ): null}
                                                        </div>
                                                    </div>
                                                    <div className="btn-box margin-top-20px margin-bottom-20px">
                                                        {/* <button className="theme-btn border-0" type="submit">
                                                            reset password
                                                        </button> */}
                                                        <Button
                                                            type="primary"
                                                            className="width100 theme-btn border-0"
                                                            onClick={() => {
                                                                handleSubmit();
                                                            }}
                                                            loading={loading}
                                                        >
                                                            send an email
                                                        </Button>
                                                    </div>
                                                    <p className="font-weight-medium">
                                                        <Link to="/login" className="color-text">Login </Link>
                                                        or
                                                        <Link to="/sign-up" className="color-text"> Register</Link>
                                                    </p>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RecoverPassBox;
/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Thursday 01, Feb 2024
 * File: Account.js
 * Dir: frontend/src/pages
 * Project: openbarcity
 */

import React, { useState } from "react";
import { signin } from "../../api/auth";
import { setHeaders } from 'lib/utils';
import { Link, useHistory } from "react-router-dom";
import { notification } from "antd";

const Account = ({ formData, handleInputChange }) => {
    const { username, password } = formData;
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const history = useHistory();
    const validateForm = () => {
        let isValid = true;
        //return email.trim() !== '' && password.trim() !== '';

        // Email validation
        if (username.trim() === '') {
            setEmailError('Email is required.');
            isValid = false;
        } else {
            setEmailError('');
        }

        // Password validation
        if (password.trim() === '') {
            setPasswordError('Password is required.');
            isValid = false;
        } else {
            setPasswordError('');
        }

        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const res = await signin(formData);
                setHeaders(res, history, '/user/events');
                history.push('/user/events');
            } catch (error) {
                let description = "";
                if (error.response.status === 400)
                    description = Object.values(error.response.data).join();
                notification.error({
                    message: "Signin failed.",
                    description,
                    placement: "bottomLeft",
                    duration: 8,
                    bottom: 100
                });
            } finally {
                //setAccountLoading(false);
            }
        } else {
            // Handle validation error
            notification.error({
                message: "Signin failed.",
                description: "Validation error: Email and password are required.",
                placement: "bottomLeft",
                duration: 8,
                bottom: 100
            });
        }
    };

    return (
        <div className="tab-pane fade show active" id="nav-account" role="tabpanel" aria-labelledby="nav-home-tab">
            <label htmlFor="inputEmail">Email address</label>
            <input
                type="email"
                id="inputEmail"
                className={`form-control ${emailError ? 'is-invalid' : ''}`}
                placeholder="Enter Email address"
                required
                autoFocus
                name="username"
                value={username}
                onChange={handleInputChange}
            />
            {emailError && <div className="invalid-feedback">{emailError}</div>}
            <label htmlFor="inputPassword" style={{ marginTop: '1rem' }}>Password</label>
            <input
                type="password"
                id="inputPassword"
                className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                placeholder="Enter Password"
                required
                name="password"
                value={password}
                onChange={handleInputChange}
            />
            {passwordError && <div className="invalid-feedback">{passwordError}</div>}
            <p className="mt-1 mb-3 text-right">
                <Link to="/recover" className="link-black">
                    Forgot password?
                </Link>
            </p>
            <button
                className="btn btn-lg btn-primary btn-block"
                type="submit"
                onClick={handleSubmit}
            >
                Sign in
            </button>
        </div>
    );
};

export default Account;
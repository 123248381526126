import axios from 'axios';

const http = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
});

http.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const access_token = localStorage.getItem('token');
        if (access_token && !config.headers.Authorization) {
            config.headers.common['Authorization'] = access_token;
        }
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
)

http.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, async function (error) {
    // Any status code that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
})

export default http;
import React from "react";
import Footer from "components/common/footer/Footer";
import GeneralHeader from "components/common/GeneralHeader";
import SectionsHeading from "components/common/SectionsHeading";
import ScrollTopBtn from "components/common/ScrollTopBtn";
import Title from "../../components/title/Title";

const WhatIsIt = () => {
  return (
    <main className="List-map-view2 background-city">
      <GeneralHeader />

      {/* What is it */}
      <section className="cta-area  column-sm-center padding-top-120px">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <Title
                title="WHAT IS IT & FEATURES"
                subTitle="We are here to serve you!"
              />
              <SectionsHeading
                title="What is it?"
                headerClass="background-city-shadow"
                titleClass=" mb-3 font-size-28"
                descClass=" font-size-17"
                desc="OpenBarCity is the perfect travel companion! It is an interactive app where you can
                                discover the culture and history of America in bars and restaurants, and open bar. In
                                between each drink, this app will take you on a deep dive into history giving you the
                                best stories, hidden secrets and insider fun facts."
              />
            </div>
          </div>
        </div>
      </section>

      {/* Features */}
      <section className="cta-area column-sm-center padding-top-20px">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <SectionsHeading
                title="Features"
                headerClass="background-city-shadow"
                titleClass=" mb-3 font-size-28"
                descClass=" font-size-17"
                desc="This app has a detailed mapping functionality to let you see where you are in every
                                city and your proximity to other destinations. Search establishments by category,
                                type, name, neighbourhood, price or cuisine. And get suggestions on cocktails that
                                suit your tastes.
                                Use our OpenBarCity app to make your next night out more pleasurable. No need for
                                anyone in your group to be the designated driver, relax connect with each other
                                because all tour include an Uber ride to take you home. Make those meaningful
                                memories with our local approach to the global appeal of cocktails & travel."
              />
            </div>
          </div>
        </div>
      </section>

      {/* Enjoy */}
      <section className="cta-area column-sm-center padding-top-20px">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className={`section-heading background-city-shadow`}>
                <h2 className={"sec__title mb-3 font-size-28"}>Enjoy OpenBarCity app in 5 ways</h2>
                <p></p>
                <h2 className={"sec__title mb-3 font-size-24"}>1. Open Bar Tour</h2>
                <p className={"sec__desc font-size-17"}>Anywhere & Anytime (at participating locations), add as many as desired. This
                  is a timed 3 hour tour & open bar experience. Taking the tour or not, is up to
                  you! The tour takes you on a historical deep dive into best stories, hidden
                  secrets and insider fun facts of New York City from the prohibition era to
                  present day.</p>
                <h2 className={"sec__title mb-3 font-size-24"}>2. Open Drink Tour</h2>
                <p className={"sec__desc font-size-17"}>Anywhere & Anytime (at participating locations), add as many as desired. This
                  is a tour experience but in increments. Take it step by step, a more relaxed
                  approach to a night out.</p>
                <h2 className={"sec__title mb-3 font-size-24"}>3. Options!</h2>
                <p className={"sec__desc font-size-17"}>Stay at one location or feel free to roam around and discover your new
                  favorite place.</p>
                <h2 className={"sec__title mb-3 font-size-24"}>4. Get Drink Suggestions</h2>
                <p className={"sec__desc font-size-17"}>With a few questions to uncover your palate, the app provides the best
                  suitable cocktails for your specific taste.</p>
                <h2 className={"sec__title mb-3 font-size-24"}>5. Mapping Functionality</h2>
                <p className={"sec__desc font-size-17"}>This app has a detailed mapping functionality to let you see where you are in the
                  city and your proximity to other destinations. Search establishments by category,
                  type, name, neighborhood, price or cuisine.</p>
                <br />
                <p className={"sec__desc font-size-17"}>Since not every hotel will have bar or restaurant services the rest of the city does! So
                  enjoy the trip you’ve always wanted and use OBC app as an all-inclusive add-on. It is
                  the perfect travel companion.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <ScrollTopBtn />
    </main>
  );
};

export default WhatIsIt;

/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Tuesday 13, Feb 2024
 * File: index.js
 * Dir: frontend/src/pages/hotels
 * Project: openbarcity
 */

import React from "react";
import Footer from "components/common/footer/Footer";
import GeneralHeader from "components/common/GeneralHeader";
import ScrollTopBtn from "components/common/ScrollTopBtn";

const BarRestaurants = () => {
    return (
        <main className="List-map-view2 background-city">
            <GeneralHeader/>
            <section className="cta-area  column-sm-center padding-top-120px">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 bg-white p-5 rounded">
                            <article>
                                <h2 className="text-center mb-3">Meet our Bar and Restaurants partners</h2>
                                <p className="mb-3">
                                    Happy to entertain visiting hotel guests, these Bars and Restaurants are on
                                    the front lines of our OnePurchased=OneDonated mission. Our ability
                                    for success with this mission could not be accomplished without them.
                                    The fantastic meals they are serving, is a much needed treat for hotel
                                    guests and those in need. The generous offerings from these Bar and
                                    Restaurants helps so many in our community and helps to keep us
                                    going.
                                </p>
                                <p className="mb-3 text-success">
                                    Thank you for all your efforts!
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
            <ScrollTopBtn/>
        </main>
    );
};

export default BarRestaurants;
/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Thursday 01, Feb 2024
 * File: LoginForm.js
 * Dir: frontend/src/pages
 * Project: openbarcity
 */

import React, { useState } from 'react';
import "../../styles/login.css";
import TabNavs from './TabNavs';
import AccountTabContent from './Account';
import AffiliateTabContent from './Affiliate';
import BarTabContent from './Bar';
import { Link } from "react-router-dom";

const LoginForm = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
    });

    const [activeTab, setActiveTab] = useState('nav-account');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleTabClick = (tabId, e) => {
        e.preventDefault();
        setActiveTab(tabId);
        // Reset form data
        setFormData({
            username: '',
            password: '',
            email: '',
        });
    };

    return (
        <form className="form-signin">
            <h1 className="h3 mb-5 font-weight-normal text-center">
                Sign in to your account
            </h1>
            <TabNavs activeTab={activeTab} handleTabClick={handleTabClick} />
            <div className="tab-content" id="nav-tabContent">
                {activeTab === 'nav-account' && (
                    <React.Fragment>
                        <AccountTabContent
                            formData={formData}
                            handleInputChange={handleInputChange}
                        />
                        <p className="mt-3 mb-3 text-center">
                            Not a member yet? <Link to="/sign-up">Register</Link>
                        </p>
                    </React.Fragment>
                )}

                {activeTab === 'nav-affiliate' && (
                    <AffiliateTabContent
                        formData={formData}
                        handleInputChange={handleInputChange}
                    />
                )}
                {activeTab === 'nav-bar' && (
                    <BarTabContent
                        formData={formData}
                        handleInputChange={handleInputChange}
                    />
                )}
{/*                {activeTab === 'nav-host' && (
                    <HotelTabContent
                        formData={formData}
                        handleInputChange={handleInputChange}
                    />
                )}*/}
            </div>
        </form>
    );
};

export default LoginForm;



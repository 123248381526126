import React from "react";
import Footer from "components/common/footer/Footer";
import GeneralHeader from "components/common/GeneralHeader";
import ScrollTopBtn from "components/common/ScrollTopBtn";
import SectionsHeading from "components/common/SectionsHeading";
import Title from "../../components/title/Title";

const Covid19 = () => {
  return (
    <main className="List-map-view2 List-map-view2-vh background-city">
      <GeneralHeader />

      {/* Covid 19 */}
      <section className="cta-area column-sm-center padding-top-120px main-flex-vh">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <Title title="COVID-19" subTitle="It’s coming Soon!" />
              <SectionsHeading
                title="What about the Covid-19 pandemic and the use of the app?"
                headerClass="background-city-shadow"
                titleClass=" mb-3 font-size-28"
                descClass=" font-size-17 "
                desc="Things will get better! With vaccines being distributed there is finally light at the end of
                                the tunnel. In response to Covid 19, states and cities are all enforcing different levels
                                of response to manage the situations and experiences. For now, some locations remain
                                closed and this may impact the use of your OpenBarCity tour. Please always check
                                the locations you wish to see and other participating websites for their most up to date
                                information. We appreciate your patience as we slowly return to a new normal. If you
                                have already purchased your OpenBarCity Tour, after activation it will be valid for at
                                least six months and as many as 12 months.
                                Thank you for your patience."
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <ScrollTopBtn />
    </main>
  );
};

export default Covid19;

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FiCheckCircle, FiCheckSquare } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { notification, Modal, Button } from "antd";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import GeneralHeader from "components/common/GeneralHeader";
import Footer from "components/common/footer/Footer";
import ScrollTopBtn from "components/common/ScrollTopBtn";
import { checkPaymentForAgent, recordPayByAgent } from "api/payment";
import { authNameSpacer } from "lib/reducers";
import { clearStorage } from "lib/utils";

function PaymentConfirmation() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("failed");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCommission, setShowCommission] = useState(false);
  const email = JSON.parse(localStorage.getItem("openbarcity_user"))?.email;
  const { paymentStatus, paymentId } = queryString.parse(location.search);
  useEffect(() => {
    if (location.search) {
      if (paymentStatus === "DONE") {
        if (paymentId !== undefined) {
          async function check() {
            try {
              let formData = new FormData();
              formData.append("payment_id", paymentId);
              const res = await checkPaymentForAgent(formData);
              const response = res.data.response;
              if (response === "new payment") {
                setStatus("success");
              } else if (response === "already purchased") {
                setStatus("already");
              }
            } catch (error) {
              if (error.response.status === 401) {
                clearStorage(dispatch, authNameSpacer);
              }
              let description = "";
              if (error.response.status === 400)
                description = Object.values(error.response.data).join();
              notification.error({
                message: "Error",
                description,
                placement: "bottomLeft",
                duration: 8,
                bottom: 100,
              });
            }
          }
          check();
        }
      }
    }
  }, [location.search, dispatch, paymentId, paymentStatus]);
  const gotoProfile = () => {
    history.push("/profile");
  };
  const getCommission = (values) => {
    async function check() {
      try {
        setLoading(true);
        let formData = new FormData();
        formData.append("payment_id", paymentId);
        formData.append("code", values["code"]);
        await recordPayByAgent(formData);
        setShowCommission(true);
        setVisible(false);
        notification.success({
          message: "Got a commission!",
          description: "You will be redirected to Profile soon",
          placement: "bottomLeft",
          duration: 3,
          bottom: 100,
        });
        setTimeout(function () {
          history.push("/profile");
        }, 3000);
      } catch (error) {
        if (error.response.status === 401) {
          clearStorage(dispatch, authNameSpacer);
        }
        let description = "";
        if (error.response.status === 400 || error.response.status === 406)
          description = Object.values(error.response.data).join();
        notification.error({
          message: "Error",
          description,
          placement: "bottomLeft",
          duration: 8,
          bottom: 100,
        });
      } finally {
        setLoading(false);
      }
    }
    check();
  };
  const initialValue = {
    code: "",
  };
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required("Required")
      .test("len", "Must be exactly 6 digits", (val) => val.length === 6),
  });

  return (
    <main className="booking-confirmation-page">
      {/* Header */}
      <GeneralHeader />

      <section className="booking-confirm-area padding-top-120px padding-bottom-140px overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className={
                  "text-center" +
                  (status === "success"
                    ? " booking-confirm-page-success"
                    : " booking-confirm-page-fail")
                }
              >
                <span className="la">
                  {status === "success" ? (
                    <FiCheckCircle />
                  ) : status === "already" ? (
                    <FiCheckSquare />
                  ) : (
                    <ImCross />
                  )}
                </span>
                <div className="section-heading pt-3">
                  <h2 className="sec__title pt-0 mb-2 before-none">
                    {status === "success"
                      ? "Thanks for your buying the ticket!"
                      : status === "already"
                      ? "Already got a commission"
                      : "Payment failed"}
                  </h2>
                  {status === "success" && (
                    <p className="sec__desc-center">
                      You'll receive an email at {email}
                    </p>
                  )}
                </div>
                {status === "success" && (
                  <div className="btn-box padding-top-30px">
                    <div
                      className={"theme-btn btn__border"}
                      onClick={() => gotoProfile()}
                    >
                      View Your tickets
                    </div>
                    {!showCommission && (
                      <div className="margin-top-30px">
                        or click the below button if you're the travel agent!
                      </div>
                    )}
                    {!showCommission && (
                      <div
                        className="theme-primary-btn margin-top-20px"
                        onClick={() => setVisible(true)}
                      >
                        Get commission
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <Modal
              title="Enter the code to get the commission"
              centered
              visible={visible}
              onCancel={() => setVisible(false)}
              footer={null}
            >
              <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={getCommission}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  return (
                    <Form>
                      <div className="input-box">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="code"
                            value={values.code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter code"
                          />
                          {errors.code && touched.code ? (
                            <div className="ant-form-item-explain">
                              {errors.code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="btn-box margin-top-20px margin-bottom-20px">
                        <Button
                          type="primary"
                          className="width100 theme-btn border-0"
                          onClick={() => {
                            handleSubmit();
                          }}
                          loading={loading}
                        >
                          Get now
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Modal>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default PaymentConfirmation;

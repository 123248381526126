import React from 'react';
import EventItem from './item';

function EventGrid ({events}) {    
    
    return (
        <>
            {events.map((item, index) => {
                return (
                    <EventItem item={item} key={index} />
                )
            })}            
        </>
    );
}

export default EventGrid;
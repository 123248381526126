import React, {Component} from 'react';
import GeneralHeader from "components/common/GeneralHeader";
import RecoverPassBox from "components/other/account/ForgotPassBox"
import Footer from "components/common/footer/Footer";
import ScrollTopBtn from "components/common/ScrollTopBtn";
import breadcrumbimg from 'assets/images/bread-bg.jpg'

class ForgotPassword extends Component {
    state = {
        breadcrumbimg: breadcrumbimg,
    }
    render() {
        return (
            <main className="List-map-view2">
                {/* Header */}
                <GeneralHeader />

                <RecoverPassBox />

                {/* Footer */}
                <Footer />

                <ScrollTopBtn />

            </main>
        );
    }
}

export default ForgotPassword;
import React, {Component} from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Footer from "../components/common/footer/Footer";
import breadcrumbimg from '../assets/images/bread-bg.jpg';
import LoginForm from "../pages/login/LoginForm";

class Login extends Component {
    state = {
        breadcrumbimg: breadcrumbimg,
    }

    render() {
        return (
            <main className="login-page">
                {/* Header */}
                <GeneralHeader/>
                <section className="form-shared padding-top-120px padding-bottom-100px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                                <LoginForm />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Footer */}
                <Footer/>
            </main>
        );
    }
}

export default Login;
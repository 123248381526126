/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Friday 23, Feb 2024
 * File: Item.jsx
 * Dir: frontend/src/pages/ecommarce
 * Project: openbarcity
 */

import React from "react";
import moment from 'moment';
import { Link } from "react-router-dom";

import arrow from "assets/images/arrow.png";

const Item = ({ meal,desc, colSize }) => {

  const start_date = moment(meal.start_date, "YYYY-MM-DD").format("MMM DD, YYYY").toString();
  let end_date = "N/A";
    if(meal.end_date){
        end_date = moment(meal.end_date, "YYYY-MM-DD").format("MMM DD, YYYY").toString();
    }
  return (
    <div className={`col-6 ${colSize ? `col-md-${colSize}` : 'col-md-4'}`}>
      <div className="card">
        <div className="card-image">
          <div
            className="img-container"
            style={{ backgroundImage: `url(${meal.image})` }}
          >
          </div>
        </div>
        <div className="card-content">
          <h4 className="card-title">{meal.name}</h4>
          {desc === 1 ? (
              <p className="truncate">{meal.description}</p>
          ) : (
              ''
          )}
        </div>

        <div className="card-action">
          <div>
            <span>From</span>
            {start_date}
          </div>
          <div>
            <img className="img-responsive" src={arrow} alt="Arrow" />
          </div>
          <div>
            <span>To</span>
            {end_date}
          </div>
        </div>
        <div className="card-cta-section">
          <Link
            to={{
              pathname:`/details/${meal.id}`,
              state: {meal}
            }}  // Add a dynamic link to the DetailPage with the event id
            className="btn-solid-lg-outline page-scroll"
          >
            Details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Item;

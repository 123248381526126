/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Tuesday 13, Feb 2024
 * File: index.js
 * Dir: frontend/src/pages/sustainableTourism
 * Project: openbarcity
 */

import React from "react";
import Footer from "components/common/footer/Footer";
import GeneralHeader from "components/common/GeneralHeader";
import ScrollTopBtn from "components/common/ScrollTopBtn";

const SustainableTourism = () => {
    return (
        <main className="List-map-view2 background-city">
            <GeneralHeader/>
            <section className="cta-area  column-sm-center padding-top-120px">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 bg-white p-5 rounded">
                            <article>
                                <h2 className="text-center mb-3">SUPPORT FOR SUSTAINABLE TOURISM INITIATIVES</h2>
                                <p className="mb-3">
                                    Tourism is a powerful economic tool but seemingly it does not always
                                    benefit the whole community. The effects of food insecurity and
                                    homelessness have been directly linked to tourism driving prices up on
                                    both food and shelter. With many not keeping up with the constantly
                                    increasing, cost of living. It seems more desirable the vacation area the
                                    higher the population of hunger and homelessness. It comes as no
                                    surprise that cases of hunger and food insecurity has increased alongside
                                    the rising cost of living that tourists inevitably fuel, but there are solutions to
                                    sustainable tourism in theTravel with Impact mission, our local hotels have
                                    brought to the city.
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
            <ScrollTopBtn/>
        </main>
    );
};

export default SustainableTourism;
import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Copyright from "components/common/footer/Copyright";
import errorimg from 'assets/images/404-img.png'

class Error extends Component {
    state = {
        img: errorimg
    }
    render() {
        return (
            <main className="error-page">
                <section className="error-area padding-top-140px">
                    <div className="error-shape"></div>
                    <div className="error-actions">
                        <ul>
                            <li><Link to="/">Back to Home</Link></li>
                        </ul>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                                <div className="error-content text-center">
                                    <img src={this.state.img} alt="error" className="w-100"/>
                                    <div className="section-heading padding-top-30px">
                                        <h3 className="sec__title pt-0 before-none">Oops! Page not found.</h3>
                                        <p className="sec__desc">
                                            The page you are looking for might have been removed,
                                            had its name changed, or is temporarily unavailable.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Copyright />
                    </div>
                </section>
            </main>
        );
    }
}

export default Error;
import React, {Component} from "react";
import Typewriter from "typewriter-effect";

export default class BannerOneHeroHeading extends Component {
    state = {
        title: "OPEN BAR CITY ",
        desc_1: "OUR GOAL-1 million+ meals donated when you support our ONE PURCHASED=ONE DONATED mission",
        desc_2: "",
        desc_3: "The city's bars and restaurants are now open, on the app!"

    };

    render() {
        return (
            <>
                <div className="hero-heading">
                    <div className="section-heading home-section-heading">
                        <h2 className="sec__title cd-headline zoom">
                            <Typewriter
                                options={{
                                    strings: `${this.state.title}`,
                                    autoStart: true,
                                    cursor: null,
                                    delay: 40,
                                }}
                            />
                        </h2>
                        <p className="sec__desc">
                            <strong>OUR GOAL-1</strong> million+ meals donated when you support our mission
                        </p>
                        <p className="sec__desc">
                            <strong>ONE PURCHASED</strong>=<strong>ONE DONATED</strong>
                        </p>
                        <p className="sec__desc">{this.state.desc_3}</p>
                    </div>
                </div>
            </>
        );
    }
}

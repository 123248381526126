import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { FaCity, FaRegEnvelope } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Select, DatePicker, notification } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import $ from "jquery";
import { authNameSpacer } from "lib/reducers";

import { signup } from "api/auth";
import { getCities } from "api/collection";
import { clearStorage, setHeaders } from "lib/utils";

const { Option } = Select;

function SignUpBox({ title, signInRedirectUrl }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);

  const signupInitial = {
    firstname: "",
    lastname: "",
    email: "",
    city: "",
    password: "",
    confirm_password: "",
    dob: null,
  };

  const signupValidationSchema = Yup.object().shape({
    firstname: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    city: Yup.string().required("Required"),
    password: Yup.string()
        .required("Required")
        .min(6, "Password must be at least 6 characters"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords don't match!")
        .required("Required"),
    dob: Yup.date()
        .nullable()
        .required("Date of Birth is required")
        .test("age", "You must be at least 21 years old", function(value) {
          return moment().diff(moment(value), "years") >= 21;
        }),
  });

  const onSubmit = async (values, { setFieldError }) => {
    try {
      setLoading(true);
      let formData = new FormData();
      for (const key in values) {
        switch (key){
          case "dob":
            formData.append(key, values[key].format("YYYY-MM-DD"));
            break;
          default:
            formData.append(key, values[key]);
        }
      }
      const influence_unique = localStorage.getItem(
          "openbarcity_influence_unique"
      );
      if (influence_unique !== null) {
        formData.append("influence", influence_unique);
      }
      console.log("formData: ",formData);
      const res = await signup(formData);
      setHeaders(res, history, signInRedirectUrl);
      if (signInRedirectUrl.includes("purchase")) {
        dispatch({
          type: authNameSpacer("SET_LOGGED_IN"),
          payload: {
            isLoggedIn: true,
            token: localStorage.getItem("openbarcity_token"),
          },
        });
        $("html, body").animate({ scrollTop: 0 }, 1000);
        notification.success({
          message: "You can purchase the ticket now.",
          placement: "topRight",
          duration: 8,
          bottom: 100,
        });
      }
    } catch (error) {
      let description = "";
      if (error.response.status === 400)
        description = Object.values(error.response.data).join();
      notification.error({
        message: "Signup failed.",
        description,
        placement: "bottomLeft",
        duration: 8,
        bottom: 100,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isCancelled = false;
    async function getData() {
      try {
        const citiesList = await getCities();
        if (!isCancelled && citiesList.data) {
          setCities(citiesList.data.response);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearStorage(dispatch);
        }
      }
    }
    getData();
    return () => {
      isCancelled = true;
    };
  }, [dispatch]);

  useEffect(() => {
    const disableSuperButtons = () => {
      // const superPrevButton = document.querySelector(
      //     ".ant-picker-header-super-prev-btn"
      // );
      const superNextButton = document.querySelector(
          ".ant-picker-header-super-next-btn"
      );

      /*if (superPrevButton) {
        superPrevButton.disabled = true;
        superPrevButton.style.pointerEvents = "none";
        superPrevButton.style.display = "none";
      }*/

      if (superNextButton) {
        superNextButton.disabled = true;
        superNextButton.style.pointerEvents = "none";
        superNextButton.style.display = "none";
      }
    };

    disableSuperButtons();

    // Reapply the disabling when DatePicker is opened
    const datePickers = document.querySelectorAll(".ant-picker");
    datePickers.forEach((picker) => {
      picker.addEventListener("click", disableSuperButtons);
    });

    return () => {
      datePickers.forEach((picker) => {
        picker.removeEventListener("click", disableSuperButtons);
      });
    };
  }, []);

  // Disable future dates in the DatePicker
  const disabledDate = (current) => {
    return current && (current > moment().endOf("day") ||  current < moment().subtract(120, "years"));
  };

  // Disable the next button in the DatePicker
  const handleOpenChange = (open) => {
    if (open) {
      setTimeout(() => {
        const nextButton = document.querySelector(
            ".ant-picker-header-next-btn"
        );
        if (nextButton) {
          nextButton.style.pointerEvents = "none";
          nextButton.style.opacity = 0.5;
        }
      }, 10);
    }
  };

  return (
      <>
        <div className="billing-form-item mb-0">
          <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
            <h3 className="widget-title font-size-28 pb-0">{title}</h3>
          </div>
          <div className="billing-content">
            <div className="contact-form-action">
              <Formik
                  initialValues={signupInitial}
                  validationSchema={signupValidationSchema}
                  onSubmit={onSubmit}
              >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <Form>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input-box">
                            <label className="label-text">First name</label>
                            <div className="form-group">
                          <span className="form-icon">
                            <AiOutlineUser />
                          </span>
                              <input
                                  className="form-control"
                                  type="text"
                                  name="firstname"
                                  placeholder="Enter first name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.firstname}
                              />
                              {errors.firstname && touched.firstname ? (
                                  <div className="ant-form-item-explain">
                                    {errors.firstname}
                                  </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-box">
                            <label className="label-text">Last name</label>
                            <div className="form-group">
                          <span className="form-icon">
                            <AiOutlineUser />
                          </span>
                              <input
                                  className="form-control"
                                  type="text"
                                  name="lastname"
                                  placeholder="Enter last name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lastname}
                              />
                              {errors.lastname && touched.lastname ? (
                                  <div className="ant-form-item-explain">
                                    {errors.lastname}
                                  </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-box">
                            <label className="label-text">Email</label>
                            <div className="form-group">
                          <span className="form-icon">
                            <FaRegEnvelope />
                          </span>
                              <input
                                  className="form-control"
                                  type="email"
                                  name="email"
                                  placeholder="Enter email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                              />
                              {errors.email && touched.email ? (
                                  <div className="ant-form-item-explain">
                                    {errors.email}
                                  </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-box">
                            <label className="label-text">City</label>
                            <div className="form-group">
                          <span className="form-icon">
                            <FaCity />
                          </span>
                              <Select
                                  style={{ width: "100%" }}
                                  placeholder="Select a city"
                                  onChange={(value) => setFieldValue("city", value)}
                                  onBlur={handleBlur}
                                  value={values.city}
                              >
                                {cities &&
                                    cities.length > 0 &&
                                    cities.map((city) => {
                                      return (
                                          <Option key={city.id} value={city.id}>
                                            {city.name}
                                          </Option>
                                      );
                                    })}
                              </Select>
                              {errors.city && touched.city ? (
                                  <div className="ant-form-item-explain">
                                    {errors.city}
                                  </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-box">
                            <label className="label-text">Date of Birth</label>
                            <div className="form-group">
                              <DatePicker
                                  name="dob"
                                  style={{ width: "100%" }}
                                  placeholder="Select Date of Birth"
                                  format="YYYY-MM-DD"
                                  disabledDate={disabledDate}
                                  onChange={(date) =>  setFieldValue("dob",  date)}
                                  onOpenChange={handleOpenChange}
                                  onBlur={handleBlur}
                                  value={values.dob}
                              />
                              {errors.dob && touched.dob ? (
                                  <div className="ant-form-item-explain">
                                    {errors.dob}
                                  </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-box">
                            <label className="label-text">Password</label>
                            <div className="form-group">
                          <span className="form-icon">
                            <FiLock />
                          </span>
                              <input
                                  className="form-control"
                                  type="password"
                                  name="password"
                                  placeholder="Enter password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                              />
                              {errors.password && touched.password ? (
                                  <div className="ant-form-item-explain">
                                    {errors.password}
                                  </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-box">
                            <label className="label-text">Confirm Password</label>
                            <div className="form-group">
                          <span className="form-icon">
                            <FiLock />
                          </span>
                              <input
                                  className="form-control"
                                  type="password"
                                  name="confirm_password"
                                  placeholder="Enter confirm password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.confirm_password}
                              />
                              {errors.confirm_password &&
                              touched.confirm_password ? (
                                  <div className="ant-form-item-explain">
                                    {errors.confirm_password}
                                  </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="btn-box margin-top-20px margin-bottom-20px">
                            <Button
                                type="primary"
                                className="width100"
                                onClick={() => {
                                  handleSubmit();
                                }}
                                loading={loading}
                            >
                              Register account
                            </Button>
                          </div>
                        </div>
                        {!(window.location.href.indexOf("purchase") > -1) && (
                            <div className="col-lg-12">
                              <p className="font-weight-medium">
                                Already have an account?{" "}
                                <Link to="/login" className="color-text">
                                  Login
                                </Link>
                              </p>
                            </div>
                        )}
                      </div>
                    </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
  );
}

export default SignUpBox;

/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Friday 23, Feb 2024
 * File: index.jsx
 * Dir: frontend/src/pages/ecommarce
 * Project: openbarcity
 */

import React, {useState, useEffect} from "react";
import "../../assets/css/ecommarce.css";
import GeneralHeader from "components/common/GeneralHeader";
import Footer from "components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import {useDispatch} from "react-redux";
import {clearStorage} from "lib/utils";
import {getHotelFeaturedMeals, getHotelMeals} from "api/event";
import {useHistory} from "react-router-dom";

import Item from "components/ecommarce/Item";

const Ecommarce = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [meals, setMeals] = useState([]);
    const [allmeals, setAllMeals] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true); // Set loading to true when starting the data fetch
                const featureMeal = await getHotelFeaturedMeals();
                setMeals(featureMeal.data.results);

                const allMeal = await getHotelMeals();
                setAllMeals(allMeal.data.results);

            } catch (error) {
                if (error.response && error.response.status === 401) {
                    clearStorage(dispatch);
                    history.push('/login');
                }
            } finally {
                setLoading(false); // Set loading to false when the fetch is completed
            }
        }

        getData();
    }, [history, dispatch]);

    return (
        <main className="List-map-view2 background-city">
            <GeneralHeader/>
            <section className="cta-area  column-sm-center padding-top-120px">
                <div id="events" className="basic-1 events-list-basic-1">
                    <div className="container">
                        <h1 className="text-center pb-0 pt-4">Featured Opportunities</h1>
                        {loading ? (
                            <div className="text-center">
                                <div className="lds-dual-ring"></div>
                            </div>
                        ) : meals.length === 0 ? (
                            <div className="row">
                                <p>No Meal found.</p>
                            </div>
                        ) : (
                            <div className="row">
                                {meals.map((meal) => (
                                    <Item key={meal.id} meal={meal} desc={1} colSize={4}/>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="container">
                        <h1 className="text-center pb-4 pt-4">Grab Your Opportunities</h1>
                        {loading ? (
                            <div className="text-center">
                                <div className="lds-dual-ring"></div>
                            </div>
                        ) : allmeals.length === 0 ? (
                            <div className="row">
                                <p>No Meal found.</p>
                            </div>
                        ) : (
                            <div className="row">
                                {allmeals.map((meal) => (
                                    <Item key={meal.id} meal={meal} desc={0} colSize={3}/>
                                ))}
                            </div>
                        )}
                    </div>

                </div>
            </section>
            <Footer/>
            <ScrollTopBtn/>
        </main>
    );
};

export default Ecommarce;


import React from "react";
import Footer from "components/common/footer/Footer";
import GeneralHeader from "components/common/GeneralHeader";
import ScrollTopBtn from "components/common/ScrollTopBtn";
import SectionsHeading from "components/common/SectionsHeading";
// import HowItWorkOneVideo from "components/hiw/hiw1/HowItWorkOneVideo";
import Title from "../../components/title/Title";

const HowToUseApp = () => {
  const subTitle = `Welcomed RW events goes to our platform and
   see the display of all the participating bar and restaurant offerings. 
   Patrons now have the ease of use to view, purchase and redeem tickets seamlessly through our app.`
  const sectionHeadingTitle = `This is an app to keep Events page lists RW events and is responsible for listings, orders & tracking`
  return (
    <main className="List-map-view2 background-city">
      <GeneralHeader />
      {/* How to get started */}
      <section className="cta-area column-sm-center padding-top-120px">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <Title title="HOW DOES IT WORK" subTitle={subTitle} />
              <SectionsHeading
                title={sectionHeadingTitle}
                headerClass="background-city-shadow"
                titleClass=" mb-3 font-size-28"
                descClass=" font-size-17"
                desc=""
              >
                <div className="how-to-container">
                  <p className={"sec__desc font-size-17"}>
                    1. Lists all RW events with the dates and times
                  </p>
                  <p className={"sec__desc font-size-17"}>
                    2. List the varying offers
                  </p>
                  <p className={"sec__desc font-size-17"}>
                    3. Buy meals and packages
                  </p>
                  <p className={"sec__desc font-size-17"}>
                    4. Redeems meals through the app
                  </p>
                  <p className={"sec__desc font-size-17"}>
                    5. Notifications when your city's RW is available
                  </p>
                  <p className={"sec__desc font-size-17"}>
                    Many operations offer several package meals at different price points. Providing several meal
                    options is important because it makes guests with varying budgets feel welcome in your restaurant.
                  </p>
                </div>
              </SectionsHeading>
            </div>
          </div>
        </div>
      </section>

      {/* How do I use app */}
      <section className="cta-area column-sm-center padding-top-20px">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <SectionsHeading
                title="How do I use the app?"
                headerClass="background-city-shadow"
                titleClass=" mb-3 font-size-28"
                descClass=" font-size-17"
              >
                <div className="how-to-container">
                  <p className={"sec__desc font-size-17"}>
                    Once in any destination bar or restaurant available through
                    the app:
                  </p>
                  <p className={"sec__desc font-size-17"}>
                    1. Open the OpenBarCity app and select “my Account”
                  </p>
                  <p className={"sec__desc font-size-17"}>
                    2. Select the tour in the account
                  </p>
                  <p className={"sec__desc font-size-17"}>
                    3. Show the bartender or server that you are using the app
                  </p>
                  <p className={"sec__desc font-size-17"}>
                    4. The bartender or server will scan their unique QRcode
                    using your phone to redeem your cocktail
                  </p>
                  <p className={"sec__desc font-size-17"}>
                    5. Once approved please enjoy your cocktail!
                  </p>
                </div>
              </SectionsHeading>
            </div>
          </div>
        </div>
      </section>

      {/* Step by step video */}
      <section className="cta-area column-sm-center padding-top-20px">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <SectionsHeading
                title="How to use video"
                headerClass="background-city-shadow"
                titleClass=" mb-3 font-size-28"
                descClass=" font-size-17"
                desc=""
              >
                <div className="how-to-container">
                  <p className={"sec__desc font-size-17"}>
                    Coming soon
                  </p>
                </div>
              </SectionsHeading>
              {/* Video */}
              <div className="col-lg-12 mx-auto padding-top-30px">
                {/* <HowItWorkOneVideo /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <ScrollTopBtn />
    </main>
  );
};

export default HowToUseApp;

import React, {Component} from "react";
import {BiDrink} from "react-icons/bi";
import {MdExplore, MdLocalDrink} from "react-icons/md";
import {FaCocktail} from "react-icons/fa";
import basil from "../../assets/images/basil-gimlet.jpg";
import adventure from "../../assets/images/adventure.jpg";
import cocktail from "../../assets/images/cocktail.jpeg";
import location from "../../assets/images/location.png";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class PlaceOne extends Component {
    states = {
        items: [
            {
                title: "To receive donations to distribute sign up your organization today!",
                cardType: "BECOME A PARTNER CHARITY",
                cardTypeIcon: <BiDrink/>,
                backgroundImg: basil,
            },
            {
                title:
                    "Be adventurous and explore beyond tourist traps, where authentic taste is",
                cardType: "BECOME A PARTNER HOST",
                cardTypeIcon: <MdExplore/>,
                backgroundImg: adventure,
            },
            {
                title: "Restaurant Week can occur any time of year, and celebrates world-famous dining scenes.",
                cardType: "SIGN UP YOUR RESTAURANT WEEK EVENT",
                cardTypeIcon: <FaCocktail/>,
                backgroundImg: cocktail,
            },
            {
                title: "With varying options from authentic cultural establishments to traditional yet unique offerings, this event provides customers with the opportunity to find new favorites.",
                cardType: "SIGN UP YOUR BAR OR RESTAURANT",
                cardTypeIcon: <MdLocalDrink/>,
                backgroundImg: location,
            },
        ],
    };
    responsive = [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ];

    render() {
        return (
            <div className="card-container">
                {this.states.items.map((item, index) => {
                    return (
                        <div className="card-item flip-card" key={index}>
                            {/* <div className="card-content-wrap"> */}
                            <div className="flip-card-inner">
                                <div
                                    className="flip-card-front"
                                    style={{backgroundImage: `url(${item.backgroundImg})`}}
                                >
                                    <p>{item.cardType}</p>
                                </div>
                                <div className="card-content flip-card-back">
                                    <h5 className="card-meta">
                                        <span>{item.cardTypeIcon}</span> {item.cardType}
                                    </h5>
                                    <h4 className="home-card-title text-muted text-center">{item.title}</h4>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    );
                })}
            </div>

            //   <div className="card-container">
            //     {this.states.items.map((item, index) => {
            //       return (
            //         <div className="card-item" key={index}>
            //           {/* <div className="card-content-wrap"> */}
            //           <div className="card-content">
            //             <h5 className="card-meta">
            //               <span>{item.cardTypeIcon}</span> {item.cardType}
            //             </h5>
            //             <h4 className="home-card-title">{item.title}</h4>
            //           </div>
            //           {/* </div> */}
            //         </div>
            //       );
            //     })}
            //   </div>
            //   <div className="row mt-5">
            //  {/* <div className="col-lg-12"> */}

            //     {/* <Slider className="places-carousel" dots={true} infinite={true} slidesToScroll={2} arrows={false} slidesToShow={3} centerMode={false} centerPadding="50px" autoplay={false} responsive={this.responsive}>
            //                     {this.states.items.map((item, index) => {
            //                         return (
            //                             <div className="card-item" key={index}>
            //                                 <div className="card-content-wrap">
            //                                     <div className="card-content">
            //                                         <h5 className="card-meta">
            //                                             <span>{item.cardTypeIcon}</span> {item.cardType}
            //                                         </h5>
            //                                         <h4 className="home-card-title">
            //                                             {item.title}
            //                                         </h4>
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                         )
            //                     })}
            //                 </Slider> */}
            //     {/* </div> */}
            // //   </div>
        );
    }
}

export default PlaceOne;

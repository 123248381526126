import React from "react";
import GeneralHeader from "components/common/GeneralHeader";
import Footer from "components/common/footer/Footer";
import ScrollTopBtn from "components/common/ScrollTopBtn";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function StripeCard() {
  const location = useLocation();
  const {
    oneCourseMeal,
    threeCourseMeal,
    bulkOneCourseMeal,
    bulkThreeCourseMeal,
  } = location.state;

  const amount =
    (oneCourseMeal ? Number(oneCourseMeal) : 0) * 5 +
    (threeCourseMeal ? Number(threeCourseMeal) : 0) * 10 +
    (bulkOneCourseMeal ? Number(bulkOneCourseMeal) : 0) * 125 +
    (bulkThreeCourseMeal ? Number(bulkThreeCourseMeal) : 0) * 250 +
    5000;

  return (
    <main className="List-map-view2">
      <GeneralHeader />

      <section className="single-listing-area padding-top-120px">
        <div className="container">
          <div className="row row-margin justify-between-row margin-top-40px">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <h3 className="text-center color-text margin-bottom-10px">
                $ {amount}
              </h3>
              <div className="text-center margin-top-20px margin-bottom-20px">
                {oneCourseMeal && <p>1 Course Bundle x {oneCourseMeal}</p>}
                {threeCourseMeal && <p>3 Course Bundle x {threeCourseMeal}</p>}
                {bulkOneCourseMeal && (
                  <p>1 Course Bulk Bundle x {bulkOneCourseMeal}</p>
                )}
                {bulkThreeCourseMeal && (
                  <p>3 Course Bulk Bundles x {bulkThreeCourseMeal}</p>
                )}
              </div>

              <h5 className="text-color-mid text-center margin-bottom-40px">
                By placing your order, you agree to follow the rules and
                regulations of each bar or restaurant you visit and our{" "}
                <a href="/terms-of-use" target="_blank">
                  conditions of use.
                </a>
              </h5>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  amount={amount}
                  oneCourseMeal={oneCourseMeal ? Number(oneCourseMeal) : 0}
                  threeCourseMeal={
                    threeCourseMeal ? Number(threeCourseMeal) : 0
                  }
                  bulkOneCourseMeal={
                    bulkOneCourseMeal ? Number(bulkOneCourseMeal) : 0
                  }
                  bulkThreeCourseMeal={
                    bulkThreeCourseMeal ? Number(bulkThreeCourseMeal) : 0
                  }
                />
              </Elements>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </section>
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default StripeCard;

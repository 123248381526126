import React, {Component} from 'react';
import SignUpBox from "../components/other/account/SignUpBox";
import GeneralHeader from "../components/common/GeneralHeader";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from '../assets/images/bread-bg.jpg';

class SignUp extends Component {
    state = {
        breadcrumbimg: breadcrumbimg,
    }
    render() {
        return (
            <main className="signup-page">
                {/* Header */}
                <GeneralHeader />

                <section className="form-shared padding-top-120px padding-bottom-100px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                                <SignUpBox title="Create an account!" signInRedirectUrl="/" />
                            </div>
                        </div>
                    </div>
                </section>

            {/* Footer */}
            <Footer />

            <ScrollTopBtn />
                
            </main>
        );
    }
}

export default SignUp;
import React, { useState } from 'react';
import Select from "react-select";

const shortby = [
    {
        value: 1,
        label: 'Comedy'
    },
    {
        value: 2,
        label: 'Live Music'
    },
    {
        value: 3,
        label: 'Games'
    },
    {
        value: 4,
        label: 'Open Ticket'
    },
]

function GenericHeader({total, filterEvents}) {
    const [selectedCatOp, setSelectedCatOp] = useState(null);
    const handleChangeshortby = (value) => {
        setSelectedCatOp(value);
        filterEvents(value.value);
    }

    return (
        <>
            <div className="generic-header margin-bottom-30px">
                <p className="showing__text text-left">
                    Total events: {total}
                </p>
                <div className="short-option mr-3">
                    <Select
                        value={selectedCatOp}
                        onChange={value => handleChangeshortby(value)}
                        placeholder="Sort by"
                        options={shortby}
                    />
                </div>
            </div>
        </>
    );
}

export default GenericHeader;